import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PropertyFiltersProvider } from "./Utils/filter";
import { BookedPropertyFiltersProvider } from "./Utils/bookedPropFilter";
import { AvailablePropertyFiltersProvider } from "./Utils/availablePropFilter";
import { ResalePropertyFiltersProvider } from "./Utils/resalePropFilter";
import { SoldoutPropertyFiltersProvider } from "./Utils/soldoutPropFilter";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { AuthProvider } from "./Components/useAuth/useAuth";
import { googleClientId } from "./Utils/urls";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={googleClientId}>
    <Provider store={store}>
      <SoldoutPropertyFiltersProvider>
        <ResalePropertyFiltersProvider>
          <AvailablePropertyFiltersProvider>
            <BookedPropertyFiltersProvider>
              <PropertyFiltersProvider>
                <App />
              </PropertyFiltersProvider>
            </BookedPropertyFiltersProvider>
          </AvailablePropertyFiltersProvider>
        </ResalePropertyFiltersProvider>
      </SoldoutPropertyFiltersProvider>
    </Provider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
