import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import "../PropertyDetails/ResponsiveComponents/responsive.css";
import inboxIcon from "../../Assets/icons/forward_to_inbox.png";
import infoIcon from "../../Assets/icons/info.png";
import PropertyDocs from "./PropertyDocs";
import { PiArchiveBox } from "react-icons/pi";
import PopUp from "../PopUp/PopUp";
import emailSentIcon from "../../Assets/icons/email_sent.png";
import emailSent2 from "../../Assets/icons/Mail_1.png";
import axios from "axios";
import {
  ApiBaseUrl,
  DocumentSentApi,
  PropertImageBaseUrl
} from "../../Utils/urls";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { convertToDocArray } from "../../Utils/convertToDocArray";
import SuccessAlert from "../Alert/SuccessAlert";
import { MdForwardToInbox } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import InfoPopup from "../InfoPopup/InfoPopup";
import {
  ClickOutside,
  DecrementToken,
  IncrementToken,
  ProceedToPurchase,
  calculateTotalPrice
} from "../../Utils/tokenCalculation";
import { getUserDetails } from "../../Api/ProfileApi";
import StickyPriceComponent from "./ResponsiveComponents/StickyPriceComponent";
import WarningAlert from "../Alert/WarningAlert";
import loader from "../../Assets/icons/Loader.gif";

function DocumentTab({ propDetails }) {
  const [activeTab, setActiveTab] = useState("document");
  const [isAccordianActive, setAccordianActive] = useState(false);
  const [showEmailsentOpoup, setShowEmailSentPopup] = useState(false);
  const [sentPropertyDoc, setSentPropertyDoc] = useState(""); //which property docs to be sent
  const propertyDocs = propDetails.property_info?.property_documents;
  const accessToken = localStorage.getItem("accestoken");
  const UserName = localStorage.getItem("user_name");
  const UserEmail = localStorage.getItem("user_email");
  const navigate = useNavigate();
  const [isPropertyEmailSent, setpropertyEmailSent] = useState(false); //property email sent state
  const [isemailNotupdated, setEmailNotupdated] = useState(false);

  const [sentCategory, setSentCategory] = useState("");
  const [showInfoPopup, setShowInfoPopup] = useState(false);

  // token section
  const location = useLocation();
  const { id } = useParams();
  const userId = localStorage.getItem("user_id");
  const { property_info, invoice_info } = propDetails;
  const [tokenCount, setTokenCount] = useState(1);
  const [formattedPrice, setFormattedPrice] = useState(0);
  const [platformFee, setPlatformFee] = useState(1);
  const [gstFee, setGstFee] = useState(1);
  const initialTotalFee =
    parseFloat(property_info?.price_per_share) + platformFee + gstFee;
  const [totalFee, setTotalFee] = useState(initialTotalFee);
  const [reachedTokenLimit, setReachedTokenLimit] = useState(false);
  const [isUserEmail, setUserEmail] = useState("");
  const [isEmailUpdated, setEmailupdated] = useState(true);
  const [isLoggedIn, setLoggedin] = useState(false);
  const [kycStatus, setKycStatus] = useState("");
  const [showStickyPriceSection, setshowStickyPriceSection] = useState(false);
  const [propertyName, setPropertyName] = useState("");
  const [isLoading, setLoading] = useState(false);
  const priceSectionRef = useRef(null);
  const [isPropComingSoon, setPropComingSoon] = useState("");

  const propertyCity = property_info?.property_city;

  const fetchUserDetails = () => {
    getUserDetails(
      userId,
      accessToken,
      setLoggedin,
      setKycStatus,
      setUserEmail,
      navigate
    );
  };

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, []);

  useEffect(() => {
    getPropertyDetails();
  }, [id]);

  //token section

  const handleshowInfoPopup = () => {
    setShowInfoPopup(true);
  };

  const handlecloseInfoPopup = () => {
    setShowInfoPopup(false);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const handletoggleAccordion = () => {
    setAccordianActive(!isAccordianActive);
  };

  const handleShowEmailSentPopUp = (property) => {
    if (!accessToken && !UserEmail && !UserName) {
      navigate("/login");
    } else if (UserEmail == "null" && UserName == "null") {
      setEmailNotupdated(true);

      setTimeout(() => {
        setEmailNotupdated(false);
        navigate("/user/profile");
      }, 3000);
    } else {
      setShowEmailSentPopup(true);
      setSentCategory(property?.category);
      setSentPropertyDoc(property?.documents);
    }
  };

  const getPropertyDetails = () => {
    fetch(
      `${ApiBaseUrl}//fr-property/fetch-property-details?property_id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return response.json();
      })
      .then((resdata) => {
        //console.log('Response from server:', resdata);

        if (resdata.success) {
          setPropertyName(resdata.data.property_info.property_name);
          setPropComingSoon(resdata.data.property_info.sale_status);
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  let docArray;

  //convert the document data to request body array
  if (sentPropertyDoc) {
    docArray = convertToDocArray(sentPropertyDoc, PropertImageBaseUrl);
  }

  const handleClosePopUp = () => {
    setShowEmailSentPopup(false);
  };

  const documentBody = {
    is_email: true,
    is_sms: false,
    notification_code: "Email-Property-Documents",
    additional_info: {
      name: UserName,
      category_name: sentCategory,
      property_name: propertyName
    },
    email_details: {
      email_id: UserEmail
    },
    docList: docArray
  };

  // const handleSendDocument=(documentBody)=>{
  //   console.log(documentBody)
  //    axios.post(DocumentSentApi,documentBody)
  //    .then((res)=>{
  //     console.log(res.data);
  //    })
  //    .catch((err)=>{
  //     console.log(err);

  //    })
  // }

  //set the fitst category active

  const handleSendDocument = (documentBody) => {
    setLoading(true);
    fetch(`${ApiBaseUrl}/fr-property-enquiry/send-property-docs`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(documentBody)
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setLoading(false);
        setpropertyEmailSent(true);
        handleClosePopUp();
        setTimeout(() => {
          setpropertyEmailSent(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (propertyDocs && propertyDocs.length > 0) {
      setActiveTab(propertyDocs[0].category);
    }
  }, [propertyDocs]);

  //token section

  //ectract the element with property invoice_details
  let invoiceDetails = invoice_info?.find((el) =>
    el?.hasOwnProperty("invoice_details")
  );

  //get the array
  invoiceDetails = invoiceDetails?.invoice_details;

  //get the element with platformm handling fee name as an object
  let phmArray = invoiceDetails?.find(
    (el) =>
      el.invoice_name.toLowerCase() === "Platform Handling fee".toLowerCase()
  );
  const phm_percentage = phmArray?.invoice_price;

  let gstArray = invoiceDetails?.find(
    (el) => el.invoice_name.toLowerCase() === "gst".toLowerCase()
  );
  const gst_percentage = gstArray?.invoice_price;

  const price = property_info?.price_per_share;
  const tokenLimit = property_info?.limit_per_share;

  const handleToggleStickyPriceSection = () => {
    setshowStickyPriceSection(!showStickyPriceSection);
  };

  const handleClickOutside = (event) => {
    ClickOutside(event, priceSectionRef, setshowStickyPriceSection);
  };

  const handleClosePushSection = () => {
    setshowStickyPriceSection(false);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIncrement = () => {
    IncrementToken(tokenCount, setTokenCount, tokenLimit, setReachedTokenLimit);
  };

  const handleDecrement = () => {
    DecrementToken(tokenCount, setTokenCount, setReachedTokenLimit);
  };

  const PerTokenPrice = property_info?.price_per_share;

  useEffect(() => {
    const { totalPrice, calculatedPlatformFee, gst_fee, total_fee } =
      calculateTotalPrice(
        PerTokenPrice,
        tokenCount,
        phm_percentage,
        gst_percentage
      );

    setFormattedPrice(totalPrice.toLocaleString());
    setPlatformFee(calculatedPlatformFee);
    setGstFee(gst_fee);
    setTotalFee(total_fee);
  }, [tokenCount, PerTokenPrice, phm_percentage, gst_percentage]);

  // Calculate the total price
  const totalPrice = price * tokenCount;

  const handleProceed = () => {
    ProceedToPurchase(
      isLoggedIn,
      kycStatus,
      isUserEmail,
      navigate,
      tokenCount,
      totalPrice,
      formattedPrice,
      property_info,
      gstFee,
      platformFee,
      totalFee,
      id,
      PerTokenPrice,
      setEmailupdated,
      location,
      propertyName,
      propertyCity
    );
  };

  return (
    <>
      {isPropertyEmailSent && (
        <SuccessAlert message="Property document is sent to your registered email" />
      )}

      {isemailNotupdated && <SuccessAlert message="Please update your email" />}

      {/* property-document-send */}
      {showEmailsentOpoup && (
        <PopUp
          width={"md"}
          showEmailsentOpoup={showEmailsentOpoup}
          onClose={handleClosePopUp}
        >
          <div class="flex flex-col gap-2 sm:gap-5">
            <div className="flex flex-col items-center gap-5 font-nunito">
              <img src={emailSent2} alt="" />
              <p className="text-blue text-lg sm:text-2xl font-bold">
                Send Documents via Email
              </p>
              <p className="  text-xs sm:text-lg text-gray text-center">
                We will share all the documents listed here with your registered
                email.
              </p>
            </div>

            <button
              onClick={() => handleSendDocument(documentBody)}
              style={{ width: "45%" }}
              className="bg-secondary mx-auto  property-popupbtn rounded-md text-white text-xs  sm:text-lg font-medium "
            >
              Send To My Email
            </button>
          </div>
        </PopUp>
      )}

      {!isEmailUpdated && <WarningAlert message="Please Update Your Email" />}
      {isLoading && (
        <div className="loader-bankAbsolute">
          <img src={loader} alt="Loader" className="bankLoader" />
        </div>
      )}

      <div class="sticky-button sm:hidden">
        <button
          disabled={property_info?.property_sale_type === "SOLD OUT"}
          onClick={handleToggleStickyPriceSection}
          className={`w-full ${
            property_info?.property_sale_type === "SOLD OUT"
              ? "bg-lightgray text-black"
              : "bg-secondary text-white"
          }  py-2.5 px-4 rounded-md font-semibold text-base`}
        >
          {property_info?.property_sale_type === "SOLD OUT"
            ? "Sold Out"
            : "Select Token"}
        </button>
      </div>

      {showStickyPriceSection && (
        <StickyPriceComponent
          formattedPrice={formattedPrice}
          gstFee={gstFee}
          handleClosePushSection={handleClosePushSection}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          handleProceed={handleProceed}
          platformFee={platformFee}
          price={price}
          priceSectionRef={priceSectionRef}
          reachedTokenLimit={reachedTokenLimit}
          showStickyPriceSection={showStickyPriceSection}
          tokenCount={tokenCount}
          tokenLimit={tokenLimit}
          totalFee={totalFee}
          isPropComingSoon={isPropComingSoon}
        />
      )}

      <div className="flex flex-col gap-9">
        {/* tab-section */}

        <div className="flex flex-col sm:flex-row gap-3.5 tab-shadow py-5  sm:py-5 sm:px-4 rounded-2xl">
          <div className="w-full docTab-sidebar sm:w-1/3">
            <div className="flex  overflow-auto sm:overflow-hidden flex-row sm:flex-col gap-4  p-4">
              {propertyDocs &&
                propertyDocs.map((category, index) => (
                  <div
                    key={index}
                    className={`cursor-pointer  whitespace-nowrap font-nunito text-xs sm:text-base  p-2 sm:py-4 text-left sm:px-3.5 rounded-md  ${
                      activeTab === category.category
                        ? "bg-secondary text-white"
                        : "text-gray border border-lightgray bg-gray-200"
                    }`}
                    onClick={() => handleTabClick(category.category)}
                  >
                    {category.category}
                  </div>
                ))}
            </div>
          </div>

          <div className="w-full sm:w-2/3  border border-lightgray rounded-lg  px-2 py-2 sm:p-4">
            {/* Mapping documents based on active tab */}
            {propertyDocs &&
              propertyDocs.map((el, index) => (
                <div
                  key={index}
                  style={{
                    display: activeTab === el.category ? "block" : "none"
                  }}
                >
                  <div className="w-full ">
                    <div className="flex text-left items-center text-black justify-between">
                      <p className="font-nunito text-sm sm:font-lg">
                        Due Diligence Documents & Reports
                      </p>
                      <div className="flex gap-4 flex-row justify-between">
                        <MdForwardToInbox
                          className="cursor-pointer"
                          style={{ fontSize: "23px", color: "#D328A7" }}
                          onClick={() => handleShowEmailSentPopUp(el)}
                        />

                        {/* <PiArchiveBox style={{ fontSize: "23px", color: "#D328A7" }} /> */}
                      </div>
                    </div>
                    {/* Rendering document component based on category */}

                    <PropertyDocs propDetails={el.documents} />
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* exit-opportunity */}
        <div>
          <div
            className={`font-nunito hs-accordion ${
              isAccordianActive ? "tab-shadow rounded-2xl p-4" : ""
            }`}
          >
            <button
              className={`font-nunito text-gray hs-accordion-toggle  px-2.5 inline-flex items-center justify-between w-full text-lg text-start text-gray-800 hover:text-gray-500 rounded-lg focus:outline-none ${
                isAccordianActive
                  ? "hs-accordion-active:text-blue-600 border-0"
                  : "border border-lightgray py-2.5"
              }`}
              onClick={handletoggleAccordion}
            >
              <div className="flex gap-2 items-center">
                <p className="text-black">Exit Opportunity</p>

                <div className="tooltip">
                  <IoMdInformationCircleOutline
                    style={{ fontSize: "14px", color: " #D328A7" }}
                  />
                  <span className="tooltiptext">
                    <p className="text-blue text-sm">
                      What is Exit Opportunity?
                    </p>
                    <p className="text-gray text-sm">
                      The investor can exit after the lock-in period of 150
                      days.
                    </p>
                  </span>
                </div>

                <IoMdInformationCircleOutline
                  style={{ fontSize: "14px", color: " #D328A7" }}
                  className="block sm:hidden"
                  onClick={handleshowInfoPopup}
                />

                {showInfoPopup && (
                  <>
                    <InfoPopup>
                      <div className="p-2">
                        <p className="text-blue text-left text-sm">
                          What is Exit Opportunity?
                        </p>
                        <p className="text-gray text-left text-sm">
                          The investor can exit after the lock-in period of 150
                          days.
                        </p>
                      </div>

                      <button
                        onClick={handlecloseInfoPopup}
                        className="bg-secondary w-full rounded-md py-2 font-bold text-sm text-white"
                      >
                        Ok
                      </button>
                    </InfoPopup>
                  </>
                )}
              </div>

              <svg
                className={`w-4 h-4 transition-transform ml-2 ${
                  isAccordianActive ? "transform rotate-90" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>

            <div
              // id={`hs-basic-with-arrow-collapse-${id}`}
              className={`font-nunito text-sm text-justify text-secondarygray hs-accordion-content w-full overflow-hidden transition-height duration-300 ease-in-out ${
                isAccordianActive ? "" : "h-0"
              }`}
            >
              <div className="flex flex-col gap-2.5 p-2.5">
                <p className="text-black">PropFTX Resale Mechanism</p>

                <p>
                  PropFTX offers a resale feature, allowing investors to sell
                  their tokens to other buyers either after a lock-in period of
                  150 days or upon the formation of the Special Purpose Vehicle
                  (SPV) before the 150-day mark for a fair exit. In the event
                  that the SPV is not formed, the invested amount will be
                  refunded to the source after deducting platform handling fees
                  and taxes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentTab;
