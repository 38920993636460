import React, { useState, useEffect } from "react";

import "./Index.css";

import BarChart from "../Barchart.js/Index";

import ProjectionFilter from "../ProjectionFilter/Index";

//import { projectionLocations } from "../../Utils/projectionLocation";
import {
  generateQuarterLabels,
  getPayloadWithQuarters,
  getRandomColor
} from "../../Utils/projectionUtils";
import { TfiFilter } from "react-icons/tfi";
import {
  fetchALMLLocations,
  getPredictedData,
  TrackLocationAvailability
} from "../../Api/ProjectionAPI";
import { ColorRing } from "react-loader-spinner";

import useDebounce from "../../Utils/Debounce";
import { amltoken } from "../../Utils/ALMLtoken";

export default function ProjectionComp() {
  const [mainCity, setMainCity] = useState("Bangalore");
  const [selectedCities, setSelectedCities] = useState(["100 Feet Ring Road"]);
  const [selectedBuilders, setSelectedBuilders] = useState([]);
  const [assetType, setAssetType] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [propertyPhases, setPropertyPhases] = useState([]);
  const [saleTypes, setSaleTypes] = useState([]);
  const [showMobFilter, setshowMobFilter] = useState(false);
  const [projectionLocations, setProjectionLocation] = useState({});
  const [isAreaLoading, setAreaLoading] = useState(false);
  const [selectedPriceRange, setSelectedPriceRange] = useState("all");
  const [buttonSelected, setbuttonSelected] = useState("Sealable");
  const [isLoading, setLoading] = useState(false);
  const [mainCitiList, setMainCityList] = useState([]);

  const userId = localStorage.getItem("user_id");
  const userName = localStorage.getItem("user_name");
  //const [filteredAreas, setFilteredArea] = useState([]);

  const togglePopup = () => {
    setshowMobFilter(!showMobFilter);
  };
  const d = new Date();
  let currentYear = d.getFullYear();

  //State for api
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [selectedQuarter, setSelectedQuarter] = useState("Jan-Mar");
  const [predictPayload, setPredictPayload] = useState([
    {
      location: selectedCities[0],
      quarter: selectedQuarter || "Jan-Mar",
      year: Number(selectedYear)
    }
  ]);

  const [predictResult, setPredictResult] = useState([]);
  const [searchInput, setSearchInput] = useState(""); //for input field only
  const [debouncedSearchInput, setDebouncedSearchInput] = useState(""); //final debounced value

  const [selectedOption, setSelectedOption] = useState(null);
  const [quarterLabels, setQuarterLabels] = useState([]);

  const accessToken = localStorage.getItem("accestoken");
  const debounce = useDebounce();

  useEffect(() => {
    setQuarterLabels(generateQuarterLabels(selectedYear, 2, 3));
  }, [selectedYear]);

  //fetching AL/ML locations
  useEffect(() => {
    fetchALMLLocations(setAreaLoading, accessToken, setProjectionLocation);
  }, []);

  useEffect(() => {
    setMainCityList(Object.keys(projectionLocations));
  }, [projectionLocations]);
  //Search filtered data

  useEffect(() => {
    if (selectedCities.length > 1) {
      setSelectedPriceRange("avg");
    } else if (selectedCities.length == 1) {
      setSelectedPriceRange("all");
    }
  }, [selectedCities]);
  //console.log(selectedCities, selectedPriceRange);

  const [userData, setUserData] = useState({
    labels: [],
    datasets: [],
    highlightedData: []
  });

  const handlePriceRangeChange = (event) => {
    setSelectedPriceRange(event.target.value);
  };

  useEffect(() => {
    setPredictPayload(
      selectedCities.map((city) => ({
        location: city,
        quarter: selectedQuarter || "Jan-Mar",
        year: Number(selectedYear)
      }))
    );
  }, [selectedCities, selectedQuarter, selectedYear]);

  useEffect(() => {
    getPredictedData(
      predictPayload,
      selectedPriceRange,
      setPredictResult,
      setLoading,
      mainCity
    );
  }, [
    selectedYear,
    selectedCities,
    predictPayload,
    selectedQuarter,
    selectedPriceRange,
    mainCity
  ]);

  useEffect(() => {
    // Create the final payload with selected cities and all quarters
    const finalPayload = getPayloadWithQuarters(
      Number(selectedYear),
      selectedQuarter,
      selectedCities
    );

    // Set the predict payload
    setPredictPayload(finalPayload);
  }, [selectedCities, selectedQuarter, selectedYear]);

  useEffect(() => {
    const processedData = processData(predictResult);
    setUserData(processedData);
  }, [predictResult, selectedYear, selectedCities, selectedQuarter]);

  const TrackLocationExistence = () => {
    TrackLocationAvailability(accessToken, searchInput);
  };

  // Handle the search input change
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    debounce((debouncedValue) => {
      setDebouncedSearchInput(debouncedValue);
    }, 500)(value); // 500ms delay
  };

  const filteredAreas =
    projectionLocations &&
    Object.keys(projectionLocations).length > 0 &&
    projectionLocations[mainCity].filter((item) =>
      item.toLowerCase().includes(debouncedSearchInput.toLowerCase())
    );

  useEffect(() => {
    if (filteredAreas && filteredAreas.length > 0) {
      setSelectedCities([filteredAreas[0]]);
    }
  }, [mainCity]);

  const debounceLocationCheck = debounce(TrackLocationExistence, 1500);
  useEffect(() => {
    if (searchInput && filteredAreas.length == 0) {
      debounceLocationCheck();
    }
  }, [filteredAreas]);

  const getFontSize = () => {
    return window.innerWidth < 768 ? 10 : 13;
  };

  const options = {
    maintainAspectRatio: false,

    scales: {
      x: {
        type: "category",
        ticks: {
          autoSkip: false,
          font: {
            size: getFontSize() // Set font size based on screen width
          }
        },
        title: {
          display: true,
          text: "Quarter/Year",
          font: {
            size: getFontSize() + 2 // Slightly larger title font size
          }
        }
      },
      y: {
        min: 2000,

        title: {
          display: true,
          text: "Price(Per Sq Ft)",
          font: {
            size: getFontSize() + 2 // Slightly larger title font size
          }
        },
        ticks: {
          font: {
            size: getFontSize() // Set font size based on screen width
          }
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: getFontSize() // Adjust legend font size for mobile
          },
          generateLabels(chart) {
            const datasets = chart.data.datasets;
            //console.log(datasets);
            return datasets.map((dataset, i) => {
              const highlightedData = dataset.data.find((data, index) => {
                return (
                  chart.data.labels[index] ===
                  `${selectedQuarter}/${selectedYear}`
                );
              });
              //console.log(highlightedData);
              const price = highlightedData ? highlightedData : 0;

              return {
                text: `${dataset.label} ₹${price?.toFixed(2)}`,
                fillStyle: dataset.backgroundColor[0],
                strokeStyle: dataset.borderColor[0],
                lineWidth: 1,

                pointStyle: "circle",
                hidden: !chart.isDatasetVisible(i),
                datasetIndex: i
              };
            });
          },
          usePointStyle: true
        }
      }
    }
  };

  const processData1 = (apiData) => {
    const labels = generateQuarterLabels(selectedYear, 2, 3);
    //console.log(apiData)
    // Group data by location
    const groupedByLocation = {};
    apiData.forEach((item) => {
      const location = item.location;
      const label = `${item.quarter}/${item.year}`;

      if (!groupedByLocation[location]) {
        groupedByLocation[location] = {};
      }
      groupedByLocation[location][label] = Number(item.predicted_price);
    });

    // Find highlighted data point
    const highlightedData = [];
    //console.log(groupedByLocation);

    // Format the data for Chart.js
    const datasets = Object.keys(groupedByLocation).map((location) => {
      const color = getRandomColor();
      const SelectedColor = getRandomColor();
      return {
        label: location,
        data: labels.map((label) => {
          const dataPoint = groupedByLocation[location][label] || 0;

          // Check if this data point is highlighted
          if (label === `${selectedQuarter}/${selectedYear}`) {
            highlightedData.push({
              location,
              quarter: selectedQuarter,
              year: selectedYear,
              price: dataPoint
            });
          }

          return dataPoint;
        }),
        backgroundColor: labels.map((label) =>
          label === `${selectedQuarter}/${selectedYear}`
            ? color // Highlight
            : color
        ),

        borderColor: labels.map((label) =>
          label === `${selectedQuarter}/${selectedYear}`
            ? color // Highlight "rgba(255, 0, 0,0.7)"
            : color
        ),
        borderWidth: labels.map((label) =>
          label === `${selectedQuarter}/${selectedYear}` ? 2 : 1
        ),
        pointStyle: labels.map((label) =>
          label === `${selectedQuarter}/${selectedYear}` ? "rect" : "circle"
        ),
        pointRadius: labels.map((label) =>
          label === `${selectedQuarter}/${selectedYear}` ? 8 : 3
        ),
        fill: false,
        spanGaps: true
      };
    });

    return {
      labels,
      datasets,
      highlightedData
    };
  };

  const processData = (apiData) => {
    const labels = generateQuarterLabels(selectedYear, 4, 3);

    // Group data by location and price type
    const groupedByLocationAndType = {};
    apiData.forEach((item) => {
      const location = item.location;
      const label = `${item.quarter}/${item.year}`;

      if (!groupedByLocationAndType[location]) {
        groupedByLocationAndType[location] = {
          avg_price: {},
          max_price: {},
          min_price: {}
        };
      }

      groupedByLocationAndType[location].avg_price[label] =
        Number(item.avg_price) || 0;
      groupedByLocationAndType[location].max_price[label] =
        Number(item.max_price) || 0;
      groupedByLocationAndType[location].min_price[label] =
        Number(item.min_price) || 0;
    });

    //  set of 5 colors
    const fixedColors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#F9FF33"];
    let colorIndex = 0;

    const datasets = [];
    Object.keys(groupedByLocationAndType).forEach((location) => {
      ["avg_price", "max_price", "min_price"].forEach((type) => {
        // Check if all data points for this type are 0
        const allValues = labels.map(
          (label) => groupedByLocationAndType[location][type][label] || 0
        );
        const hasNonZeroValues = allValues.some((value) => value !== 0);

        if (hasNonZeroValues) {
          const color = fixedColors[colorIndex % fixedColors.length];
          colorIndex++;

          const labelPrefix = selectedCities.length > 1 ? `${location} - ` : "";

          const typeLabel = type
            .replace("_", " ")
            .toUpperCase()
            .replace(" PRICE", "");

          datasets.push({
            label: `${labelPrefix}${typeLabel}`,

            data: allValues,
            backgroundColor: labels.map((label) =>
              label === `${selectedQuarter}/${selectedYear}` ? color : color
            ),
            borderColor: labels.map((label) =>
              label === `${selectedQuarter}/${selectedYear}` ? color : color
            ),
            borderWidth: labels.map((label) =>
              label === `${selectedQuarter}/${selectedYear}` ? 2 : 1
            ),
            pointStyle: labels.map((label) =>
              label === `${selectedQuarter}/${selectedYear}` ? "rect" : "circle"
            ),
            pointRadius: labels.map((label) =>
              label === `${selectedQuarter}/${selectedYear}` ? 8 : 3
            ),
            fill: false,
            spanGaps: true
          });
        }
      });
    });

    // Identify highlighted data
    const highlightedData = [];
    Object.keys(groupedByLocationAndType).forEach((location) => {
      ["avg_price", "max_price", "min_price"].forEach((type) => {
        const highlightedPrice =
          groupedByLocationAndType[location][type][
            `${selectedQuarter}/${selectedYear}`
          ];
        if (highlightedPrice) {
          highlightedData.push({
            location,
            quarter: selectedQuarter,
            year: selectedYear,
            priceType: type,
            price: highlightedPrice
          });
        }
      });
    });

    return {
      labels,
      datasets,
      highlightedData
    };
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this threshold as needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLocationSelect = (location) => {
    setSelectedCities((prevSelectedCities) => {
      const updatedCities = prevSelectedCities.includes(location)
        ? prevSelectedCities.filter((loc) => loc !== location)
        : [...prevSelectedCities, location];

      // Update the predict payload with the new city list and fetch data
      const updatedPayload = getPayloadWithQuarters(
        selectedYear,
        selectedQuarter,
        updatedCities
      );
      setPredictPayload(updatedPayload);
      //getPredictedData(updatedPayload); // Fetch data with updated payload

      // getPredictedData(
      //   updatedPayload,
      //   selectedPriceRange,
      //   setPredictResult,
      //   setLoading,
      //   mainCity
      // );
      return updatedCities;
    });
  };

  if (isMobile) {
    // Render mobile view
    return (
      <>
        <div className=" -mt-6 flex flex-row justify-between marginAnalHelp">
          <div className="flex flex-col justify-center">
            {/* <IoMdArrowBack style={{height:"26px",width:"26px"}} /> */}
          </div>

          <div className="anaMobText">Price Projections</div>
          <div
            className="flex flex-col justify-center cursor-pointer"
            onClick={togglePopup}
          >
            <TfiFilter style={{ fontSize: "20px" }} />
          </div>
        </div>
        <div className=" flex flex-col gap-[15px] mt-[30px]">
          {/* headAnalytics */}
          <div className="text-md">
            Property Price Comparison Based on Micro Market
          </div>
          {selectedCities.length == 1 && (
            <p className="font-medium">{selectedCities[0]}</p>
          )}
          {/* mobile-view */}
          <div className="shadow-lg overflow-x-auto px-4 py-2 rounded-md mb-10">
            <div
              className=""
              style={{ height: "460px", width: "700px", overflowX: "auto" }}
            >
              <BarChart chartData={userData} chartOptions={options} />
            </div>
          </div>
        </div>
        {/* <div className=' flex flex-row justify-between mt-[20px]'>
        <div className='h-[44px] flex flex-col justify-center px-[30px] rounded-md buttonFontAna borderSealable'>Sealable Price</div>
        <div className='h-[44px] border flex flex-col justify-center px-[30px] rounded-md bg-[#D328A7] text-white buttonFontAna'>Rental Price</div>
      </div> */}

        {showMobFilter && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="h-full w-full">
              <ProjectionFilter
                setshowMobFilter={setshowMobFilter}
                showMobFilter={showMobFilter}
                selectedCities={selectedCities}
                selectedBuilders={selectedBuilders}
                setSelectedBuilders={setSelectedBuilders}
                assetType={assetType}
                setAssetType={setAssetType}
                priceRange={priceRange}
                setPriceRange={setPriceRange}
                propertyPhases={propertyPhases}
                setPropertyPhases={setPropertyPhases}
                saleTypes={saleTypes}
                setSaleTypes={setSaleTypes}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                handleLocationSelect={handleLocationSelect}
                selectedQuarter={selectedQuarter}
                setSelectedQuarter={setSelectedQuarter}
                setSelectedCities={setSelectedCities}
                selectedPriceRange={selectedPriceRange}
                handlePriceRangeChange={handlePriceRangeChange}
                mainCity={mainCity}
                setMainCity={setMainCity}
                searchInput={searchInput}
                handleSearchChange={handleSearchChange}
                // setSearchInput={setSearchInput}
                filteredAreas={filteredAreas}
                mainCitiList={mainCitiList}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <div className=" flex flex-col font-nunito ">
        <div className=" text-start anaText">Price Projections</div>
        <div className=" mt-[30px] flex flex-col parentBorderHelperClass p-[20px]">
          <div className=" flex flex-row justify-between">
            <div className=" priceCompText">
              <div className="flex gap-1 items-center py-2">
                <p> Property Price Comparison Based on Micro Market</p>

                {isLoading ? (
                  <ColorRing
                    visible={true}
                    height="30"
                    width="30"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                      "#d328a7",
                      "#d328a7",
                      "#d328a7",
                      "#d328a7",
                      "#d328a7",
                      "#d328a7"
                    ]}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            {selectedCities.length > 1 && (
              <div className=" flex flex-row gap-[30px]">
                <div className=" flex flex-row gap-2 ">
                  <input
                    type="radio"
                    name="price"
                    id="minPrice"
                    value="min"
                    checked={selectedPriceRange === "min"}
                    onChange={handlePriceRangeChange}
                  />
                  <label
                    htmlFor="minPrice"
                    className={`flex flex-col justify-center ${
                      selectedPriceRange === "min" ? "selected" : ""
                    }`}
                  >
                    Minimum Price
                  </label>
                </div>

                <div className=" flex flex-row gap-2">
                  <input
                    type="radio"
                    name="price"
                    id="avgPrice"
                    value="avg"
                    checked={selectedPriceRange === "avg"}
                    onChange={handlePriceRangeChange}
                  />
                  <label
                    htmlFor="avgPrice"
                    className={`flex flex-col justify-center ${
                      selectedPriceRange === "avg" ? "selected" : ""
                    }`}
                  >
                    Average Price
                  </label>
                </div>

                <div className=" flex flex-row gap-2">
                  <input
                    type="radio"
                    name="price"
                    id="maxPrice"
                    value="max"
                    checked={selectedPriceRange === "max"}
                    onChange={handlePriceRangeChange}
                  />
                  <label
                    htmlFor="maxPrice"
                    className={`flex flex-col justify-center ${
                      selectedPriceRange === "max" ? "selected" : ""
                    }`}
                  >
                    Maximum Price
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className=" flex flex-row justify-between mt-[35px]">
            <div
              className=" flex flex-col px-[10px] py-[14px] leftBorderAna"
              style={{ width: "18%", maxHeight: "400px", overflow: "auto" }}
            >
              <div className=" flex flex-row justify-between mb-2">
                <div>Areas</div>

                {/* <div className="rounded-md bg-[#EBEFF6] px-[10px] py-[4px]">Selected</div>
                 */}
              </div>
              <div className=" flex flex-col gap-3">
                {/* we have to make dynamic this part map the label and color here here  */}
                <input
                  type="text"
                  className="border border-lightgray px-1"
                  placeholder="Search areas...."
                  value={searchInput}
                  onChange={handleSearchChange}
                />

                {filteredAreas.length > 0 ? (
                  filteredAreas.map((el) => (
                    <div className="flex flex-row text-start" key={el}>
                      <input
                        type="checkbox"
                        checked={selectedCities.includes(el)}
                        onChange={() => handleLocationSelect(el)}
                        disabled={
                          selectedCities.length >= 5 &&
                          !selectedCities.includes(el)
                        }
                      />
                      <span className="ml-2">{el}</span>
                      <div className="flex flex-col justify-center"></div>
                    </div>
                  ))
                ) : searchInput === "" && isAreaLoading ? (
                  <p>Loading...</p>
                ) : (
                  <div>No results found</div>
                )}
              </div>
            </div>
            <div className=" flex flex-col ml-[20px] " style={{ width: "82%" }}>
              <div className=" flex flex-row gap-[10px] items-center mb-4">
                <select
                  name=""
                  value={mainCity}
                  className="px-[2px] py-[4px] rounded-md"
                  style={{ border: "1px solid #CCCCCC" }}
                  onChange={(e) => setMainCity(e.target.value)}
                >
                  <option value="" disabled>
                    Select City
                  </option>
                  {mainCitiList.length > 0 &&
                    mainCitiList.map((city) => (
                      <option value={city}>{city}</option>
                    ))}
                </select>

                <select
                  name=""
                  value={selectedQuarter}
                  className="px-[2px] py-[4px] rounded-md"
                  style={{ border: "1px solid #CCCCCC" }}
                  onChange={(e) => setSelectedQuarter(e.target.value)}
                >
                  <option value="" disabled>
                    Select Quarter
                  </option>
                  <option value="Jan-Mar">January-March</option>
                  <option value="Apr-Jun">April-June</option>
                  <option value="Jul-Sep">July-September</option>
                  <option value="Oct-Dec">October-December</option>
                </select>

                <select
                  className="px-[2px] py-[4px] rounded-md "
                  style={{ border: "1px solid #CCCCCC" }}
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(Number(e.target.value))}
                >
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>\
                  <option value="2030">2030</option>
                </select>

                {/* <select name="" 
                   className='px-[2px] py-[4px] rounded-md' 
                   style={{border:"1px solid #CCCCCC"}}
                  value={"Residential"} disabled >
                    <option value="">Asset Types</option>
                      <option value="Residential">Residential</option>
                    
                 </select> */}

                <div
                  className="flex flex-row justify-between px-[12px] py-[4px] rounded-md border gap-2"
                  style={{ border: "1px solid #CCCCCC" }}
                >
                  <div className="text-[#686868]">Residential</div>
                </div>

                {/* {userData.highlightedData.length > 0 && (
      <div className="highlighted-data h-20 border border-lightgray rounded-md overflow-y-auto">
        <h3 className='text-blue font-semibold'>Price Projections:</h3>
        {userData.highlightedData.map((data, index) => (
          <div key={index} className='px-3 text-left'>
            <p>{data.location} <span className='text-secondary'> ({data.price.toFixed(2)})</span></p>
          
           
          </div>
        ))}
      </div>
    )} */}
              </div>
              {selectedCities.length == 1 && (
                <p className="font-medium">{selectedCities[0]}</p>
              )}
              <div
                className=" flex flex-col justify-center"
                style={{ height: "100%" }}
              >
                <BarChart chartData={userData} chartOptions={options} />
              </div>

              {/* <div className="flex flex-row justify-center gap-[26px] mt-[20px]">
                <div className={`px-[14px] py-[6px] text-[12px] ${buttonSelected=== "Sealable" ? "sealRentButton" : "unSealRentButton"}    `} onClick={()=>setbuttonSelected("Sealable")}>Sealable Price</div>
                <div className={`px-[14px] py-[6px] text-[12px]  ${buttonSelected=== "Rental" ? "sealRentButton" : "unSealRentButton"}   `} onClick={()=>setbuttonSelected("Rental")}>Rental Price</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
