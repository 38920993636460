export const getPayloadWithQuarters = (
  selectedYear,
  selectedQuarter,
  selectedCities
) => {
  const payload = [];

  // Add the selected quarter for the selected year
  if (selectedQuarter) {
    selectedCities.forEach((city) => {
      payload.push({
        location: city,
        quarter: selectedQuarter,
        year: Number(selectedYear)
      });
    });
  }

  // Add all quarters for the previous 3 years
  // for (let year = (Number(selectedYear) - 2 ); year < Number(selectedYear); year++) {
  //   selectedCities.forEach(city => {
  //     payload.push({ location: city, quarter: "Jan-Mar", year });
  //     payload.push({ location: city, quarter: "Apr-Jun", year });
  //     payload.push({ location: city, quarter: "Jul-Sep", year });
  //     payload.push({ location: city, quarter: "Oct-Dec", year });
  //   });
  // }

  // Add all quarters for the selected year
  // selectedCities.forEach(city => {
  //   payload.push({ location: city, quarter: "Jan-Mar", year: Number(selectedYear) });
  //   payload.push({ location: city, quarter: "Apr-Jun", year: Number(selectedYear) });
  //   payload.push({ location: city, quarter: "Jul-Sep", year: Number(selectedYear) });
  //   payload.push({ location: city, quarter: "Oct-Dec", year: Number(selectedYear) });
  // });

  // Add all quarters for the next 3 years
  // for (let year = Number(selectedYear) + 1; year <= (Number(selectedYear) + 3); year++) {
  //   selectedCities.forEach(city => {
  //     payload.push({ location: city, quarter: "Jan-Mar", year });
  //     payload.push({ location: city, quarter: "Apr-Jun", year });
  //     payload.push({ location: city, quarter: "Jul-Sep", year });
  //     payload.push({ location: city, quarter: "Oct-Dec", year });
  //   });
  // }

  // Add all quarters for the next 3 years
  //  for (let year = Number(selectedYear) + 1; year <= (Number(selectedYear) + 3); year++) {
  //   selectedCities.forEach(city => {
  //     payload.push({ location: city, quarter: "Jan-Mar", year });
  //     payload.push({ location: city, quarter: "Apr-Jun", year });
  //     payload.push({ location: city, quarter: "Jul-Sep", year });
  //     payload.push({ location: city, quarter: "Oct-Dec", year });
  //   });

  // Add all quarters from 2024 to 2030
  for (let year = 2024; year <= 2030; year++) {
    selectedCities.forEach((city) => {
      payload.push({ location: city, quarter: "Jan-Mar", year });
      payload.push({ location: city, quarter: "Apr-Jun", year });
      payload.push({ location: city, quarter: "Jul-Sep", year });
      payload.push({ location: city, quarter: "Oct-Dec", year });
    });
  }

  return payload;
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Utility function to generate all quarters from previous x years to next x years relative to selected year
export const generateQuarterLabels = (
  selectedYear,
  numberOfYearsBefore,
  numberOfYearsAfter
) => {
  const quarters = [];
  let selectYearNo = Number(selectedYear);
  // for (let year = selectYearNo - numberOfYearsBefore; year <=( selectYearNo + numberOfYearsAfter); year++) {

  //   quarters.push(`Jan-Mar/${year}`);
  //   quarters.push(`Apr-Jun/${year}`);
  //   quarters.push(`Jul-Sep/${year}`);
  //   quarters.push(`Oct-Dec/${year}`);

  // }
  //console.log(quarters)

  //generate all quarters label from 2024 to 2030
  for (let year = 2024; year <= 2030; year++) {
    quarters.push(`Jan-Mar/${year}`);
    quarters.push(`Apr-Jun/${year}`);
    quarters.push(`Jul-Sep/${year}`);
    quarters.push(`Oct-Dec/${year}`);
    //console.log("yrs"+year);
  }

  return quarters;
};
