import React from "react";
import ReactSlider from "react-slider";

export default function InvestmentRiskInput({
  investmentAmount,
  setinvestmentAmount,
  setMinValErr,
  isMinValErr,
  handleRiskCapacityChange,
  selectedRiskCapacity,
  timePeriod,
  setTimePeriod
}) {
  return (
    <div className="flex flex-col    gap-3 p-2.5 border border-lightgray rounded-lg">
      <p className="text-xs font-bold text-left sm:block hidden">
        Investment Inputs
      </p>

      <div className="flex flex-col gap-3 sm:gap-5   divide-y divide-lightgray ">
        <div className="flex flex-col gap-4 sm:gap-5 pb-9 pt-4">
          <div className="flex justify-between items-center">
            <p className="text-xs font-bold ">Amount to Invest</p>

            <input
              id="investment"
              type="number"
              value={investmentAmount}
              min={5000}
              onChange={(e) => {
                const inputValue = e.target.value;
                setinvestmentAmount(
                  inputValue === "" ? "" : parseInt(inputValue, 10)
                );
                if (inputValue === "" || inputValue < 5000) {
                  setMinValErr(true);
                  //setinvestmentAmount(5000);
                } else {
                  setMinValErr(false);
                }

                // onBlur={() => {
                //   if (
                //     investmentAmount === "" ||
                //     investmentAmount < 5000
                //   ) {
                //     setMinValErr(true);
                //     setinvestmentAmount(5000);
                //   } else {
                //     setMinValErr(false);
                //   }
                // }}
              }}
              className={`bg-lightgray rounded-md px-2 ${
                isMinValErr ? "border border-red" : ""
              }`}
            />
          </div>

          <p className="text-xs text-red ">
            {isMinValErr ? "Minimun allowed value is 5000" : ""}
          </p>
          <ReactSlider
            value={investmentAmount}
            onChange={(value) => {
              if (value === "" || value < 5000) {
                setMinValErr(true);
              } else {
                setMinValErr(false);
                setinvestmentAmount(value);
              }
            }}
            className="custom-slider"
            thumbClassName="thumb"
            markClassName="example-mark"
            renderTrack={(props, state) => (
              <div
                {...props}
                className={`track ${
                  state.index === 0 ? "track-selected" : "track-unselected"
                }`}
              />
            )}
            renderThumb={(props, state) => (
              <div {...props}>
                <div className="tooltip-advisor">{state.valueNow}</div>
              </div>
            )}
            min={5000}
            max={1000000}
          />
        </div>

        <div className="flex flex-col gap-3 sm:gap-5 pt-4">
          <p className="text-xs font-bold text-left">Risk Capacity</p>

          <div className="flex gap-5 sm:gap-7">
            <div className="flex flex-col gap-2.5 justify-between">
              <input
                type="radio"
                value={"Low"}
                onChange={handleRiskCapacityChange}
                checked={selectedRiskCapacity === "Low"}
              />
              <label>Low</label>
            </div>
            <div className="flex flex-col gap-2.5 justify-between">
              <input
                type="radio"
                value={"Moderate"}
                onChange={handleRiskCapacityChange}
                checked={selectedRiskCapacity === "Moderate"}
              />

              <label>Moderate</label>
            </div>
            <div className="flex flex-col gap-2.5 justify-between">
              <input
                type="radio"
                value={"High"}
                onChange={handleRiskCapacityChange}
                checked={selectedRiskCapacity === "High"}
              />
              <label>High</label>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3 sm:gap-5 pb-12 pt-4">
          <div className="flex justify-between items-center">
            <p className="text-xs font-bold ">
              Time Period <span className="text-[10px]">(Years)</span>{" "}
            </p>
            <input
              type="number"
              min={1}
              max="5"
              className="bg-lightgray rounded-md px-2"
              value={timePeriod}
              onChange={(e) => setTimePeriod(e.target.value)}
              disabled
            />
          </div>
          <ReactSlider
            value={timePeriod}
            onChange={(val) => setTimePeriod(val)}
            className="custom-slider"
            thumbClassName="thumb"
            markClassName="example-mark"
            renderTrack={(props, state) => (
              <div
                {...props}
                className={`track ${
                  state.index === 0 ? "track-selected" : "track-unselected"
                }`}
              />
            )}
            renderThumb={(props, state) => (
              <div {...props}>
                <div className="tooltip-advisor">{state.valueNow}</div>
              </div>
            )}
            min={1}
            max={5}
          />
        </div>
      </div>
    </div>
  );
}
