import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { statesData } from "./testMapData";

const Map2 = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) return;

    const map = L.map("map").setView([12.9716, 77.5946], 13);
    mapRef.current = map;

    // Add a tile layer
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 18,
      attribution: "© OpenStreetMap contributors"
    }).addTo(map);

    // Define color scale for density
    function getColorForDensity(d) {
      return d > 90
        ? "#800026"
        : d > 50
        ? "#BD0026"
        : d > 20
        ? "#E31A1C"
        : d > 10
        ? "#FC4E2A"
        : d > 0
        ? "#FD8D3C"
        : "#FFEDA0";
    }

    // Define color scale for population
    function getColorForPopulation(d) {
      return d > 5000000
        ? "#800026"
        : d > 1000000
        ? "#BD0026"
        : d > 500000
        ? "#E31A1C"
        : "#FC4E2A";
    }

    // Define color scale for area
    function getColorForArea(d) {
      return d > 500000
        ? "#800026"
        : d > 100000
        ? "#BD0026"
        : d > 50000
        ? "#E31A1C"
        : "#FC4E2A";
    }

    // Style each state based on property (density, population, or area)
    function styleDensity(feature) {
      return {
        fillColor: getColorForDensity(feature.properties.density),
        weight: 2,
        opacity: 1,
        color: "white",
        dashArray: "3",
        fillOpacity: 0.7
      };
    }

    function stylePopulation(feature) {
      return {
        fillColor: getColorForPopulation(feature.properties.population),
        weight: 2,
        opacity: 1,
        color: "white",
        dashArray: "3",
        fillOpacity: 0.7
      };
    }

    function styleArea(feature) {
      return {
        fillColor: getColorForArea(feature.properties.area),
        weight: 2,
        opacity: 1,
        color: "white",
        dashArray: "3",
        fillOpacity: 0.7
      };
    }

    // Highlight a feature on hover
    function highlightFeature(e) {
      const layer = e.target;

      layer.setStyle({
        weight: 5,
        color: "#666",
        dashArray: "",
        fillOpacity: 0.7
      });

      layer.bringToFront();
    }

    function resetHighlight(e) {
      geojson.resetStyle(e.target);
    }

    function zoomToFeature(e) {
      map.fitBounds(e.target.getBounds());
    }

    function onEachFeature(feature, layer) {
      const tooltipContent = `
        <strong>${feature.properties.name}</strong><br />
        Density: ${feature.properties.density} per sq mi<br />
        Price: ${feature.properties.price}<br />

      `;

      layer.bindTooltip(tooltipContent, {
        permanent: false,
        direction: "auto",
        opacity: 0.8,
        className: "leaflet-tooltip-custom"
      });

      layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: zoomToFeature
      });
    }

    // Add GeoJSON layer
    const geojson = L.geoJson(statesData, {
      style: styleDensity,
      onEachFeature: onEachFeature
    }).addTo(map);
  }, []);

  return <div id="map" style={{ height: "600px", width: "100%" }}></div>;
};

export default Map2;
