export let statesData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "01",
      properties: { name: "HSR, Bangalore", density: 94.65, price: 1234 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.6236224, 12.9169694],
            [77.6236439, 12.9164386],
            [77.6237221, 12.9162488],
            [77.624267, 12.9152869],
            [77.6244128, 12.9149242],
            [77.6253739, 12.9132465],
            [77.6261075, 12.912121],
            [77.6268625, 12.9108334],
            [77.626833, 12.910815],
            [77.626477, 12.910795],
            [77.626038, 12.910849],
            [77.625761, 12.910641],
            [77.625832, 12.910509],
            [77.626018, 12.910176],
            [77.626252, 12.909758],
            [77.625799, 12.909374],
            [77.625661, 12.909156],
            [77.625508, 12.908478],
            [77.625853, 12.908433],
            [77.626133, 12.908417],
            [77.627225, 12.908185],
            [77.627996, 12.908135],
            [77.62847, 12.908106],
            [77.628664, 12.907782],
            [77.629001, 12.907201],
            [77.629088, 12.907073],
            [77.629175, 12.906941],
            [77.629262, 12.906816],
            [77.62986, 12.906743],
            [77.63035, 12.906918],
            [77.63057, 12.906885],
            [77.630878, 12.907025],
            [77.63099, 12.906737],
            [77.631429, 12.906838],
            [77.631802, 12.906685],
            [77.632403, 12.906665],
            [77.632833, 12.906656],
            [77.634067, 12.907032],
            [77.634518, 12.907259],
            [77.635416, 12.907387],
            [77.635341, 12.906992],
            [77.634885, 12.906163],
            [77.635069, 12.906141],
            [77.635298, 12.906662],
            [77.635469, 12.906925],
            [77.635672, 12.90711],
            [77.635993, 12.906905],
            [77.63706, 12.906396],
            [77.63801, 12.906048],
            [77.638043, 12.906037],
            [77.638573, 12.905859],
            [77.639003, 12.905775],
            [77.639233, 12.905716],
            [77.6406, 12.905512],
            [77.641481, 12.905333],
            [77.642651, 12.905009],
            [77.643543, 12.904808],
            [77.64425, 12.904643],
            [77.645825, 12.904276],
            [77.645798, 12.904335],
            [77.645608, 12.904599],
            [77.645273, 12.905449],
            [77.644938, 12.9063],
            [77.64446, 12.907142],
            [77.645538, 12.906851],
            [77.646628, 12.906566],
            [77.647719, 12.906281],
            [77.649072, 12.906033],
            [77.649073, 12.905979],
            [77.649066, 12.905724],
            [77.64906, 12.905354],
            [77.649051, 12.905221],
            [77.649023, 12.904862],
            [77.649014, 12.904742],
            [77.648976, 12.904241],
            [77.648959, 12.904008],
            [77.648937, 12.903765],
            [77.648902, 12.903288],
            [77.648741, 12.903273],
            [77.648346, 12.903245],
            [77.648372, 12.902893],
            [77.648279, 12.90234],
            [77.648286, 12.902092],
            [77.648312, 12.901806],
            [77.648623, 12.901201],
            [77.648677, 12.901204],
            [77.648988, 12.901304],
            [77.649434, 12.90146],
            [77.650784, 12.90197],
            [77.65099, 12.902033],
            [77.65106, 12.902061],
            [77.651966, 12.901132],
            [77.652345, 12.901521],
            [77.652268, 12.902561],
            [77.65228, 12.902747],
            [77.653871, 12.902532],
            [77.654032, 12.903332],
            [77.654121, 12.903802],
            [77.654515, 12.903701],
            [77.655198, 12.903526],
            [77.655434, 12.904422],
            [77.65567, 12.905317],
            [77.655906, 12.906212],
            [77.656142, 12.907107],
            [77.656398, 12.908057],
            [77.656653, 12.909006],
            [77.657062, 12.909437],
            [77.657048, 12.909512],
            [77.658331, 12.909779],
            [77.659615, 12.910046],
            [77.660324, 12.909859],
            [77.660288, 12.9107],
            [77.660609, 12.912249],
            [77.661076, 12.913632],
            [77.660464, 12.914918],
            [77.659913, 12.91595],
            [77.659474, 12.916792],
            [77.659035, 12.917633],
            [77.65788, 12.918529],
            [77.657107, 12.91979],
            [77.656414, 12.920485],
            [77.655722, 12.92118],
            [77.655173, 12.921709],
            [77.655081, 12.922101],
            [77.655061, 12.922374],
            [77.654974, 12.922692],
            [77.654988, 12.923115],
            [77.655057, 12.923192],
            [77.655015, 12.923507],
            [77.654986, 12.923561],
            [77.654593, 12.923632],
            [77.653304, 12.923884],
            [77.651714, 12.924182],
            [77.651371, 12.924233],
            [77.651305, 12.924161],
            [77.6511326, 12.924212],
            [77.650933, 12.924271],
            [77.650355, 12.924359],
            [77.6505, 12.924546],
            [77.650017, 12.924738],
            [77.649748, 12.924838],
            [77.64974, 12.924949],
            [77.64948, 12.924847],
            [77.648882, 12.924765],
            [77.6488219, 12.9247829],
            [77.648521, 12.924752],
            [77.647553, 12.924786],
            [77.646905, 12.924589],
            [77.646429, 12.924654],
            [77.645904, 12.924771],
            [77.645529, 12.924778],
            [77.645083, 12.92472],
            [77.644898, 12.924697],
            [77.644116, 12.924447],
            [77.643719, 12.924325],
            [77.643197, 12.924129],
            [77.642215, 12.924],
            [77.641341, 12.92364],
            [77.641155, 12.923797],
            [77.640771, 12.924037],
            [77.639936, 12.924304],
            [77.639911, 12.924932],
            [77.63996, 12.926119],
            [77.639465, 12.926266],
            [77.639334, 12.925297],
            [77.639414, 12.925237],
            [77.639304, 12.924434],
            [77.6391984, 12.9240468],
            [77.639154, 12.923884],
            [77.638593, 12.923944],
            [77.637996, 12.924007],
            [77.637407, 12.923696],
            [77.637269, 12.923583],
            [77.63716, 12.923488],
            [77.636864, 12.923504],
            [77.636653, 12.923517],
            [77.636424, 12.923602],
            [77.636385, 12.923822],
            [77.63608, 12.923802],
            [77.635738, 12.923784],
            [77.635694, 12.923348],
            [77.634856, 12.92349],
            [77.634734, 12.923513],
            [77.634774, 12.923859],
            [77.634023, 12.923916],
            [77.633962, 12.923309],
            [77.6339, 12.922785],
            [77.633855, 12.922425],
            [77.633843, 12.922181],
            [77.633723, 12.921719],
            [77.633514, 12.921768],
            [77.633248, 12.920928],
            [77.633739, 12.920969],
            [77.634465, 12.920775],
            [77.634735, 12.920537],
            [77.634782, 12.920315],
            [77.634702, 12.920132],
            [77.634157, 12.919249],
            [77.634021, 12.919125],
            [77.633829, 12.919173],
            [77.633571, 12.91896],
            [77.633258, 12.918868],
            [77.632939, 12.919219],
            [77.632735, 12.919174],
            [77.632746, 12.919336],
            [77.632425, 12.919742],
            [77.632093, 12.91862],
            [77.631797, 12.916869],
            [77.631574, 12.91685],
            [77.630961, 12.916537],
            [77.630199, 12.916528],
            [77.630016, 12.916768],
            [77.629794, 12.916964],
            [77.629333, 12.917014],
            [77.628703, 12.916875],
            [77.628307, 12.9169],
            [77.6285436, 12.9180016],
            [77.6260606, 12.9187132],
            [77.6253494, 12.9188839],
            [77.6251312, 12.9189732],
            [77.624031, 12.919115],
            [77.6239752, 12.9189039],
            [77.624014, 12.9188778],
            [77.6238111, 12.9183247],
            [77.623841, 12.9180242],
            [77.623727, 12.9180216],
            [77.6237351, 12.9177563],
            [77.6237632, 12.9170086],
            [77.6236224, 12.9169694]
          ]
        ]
      }
    },
    {
      type: "Feature",
      id: "02",
      properties: { name: "Chokkanahalli", density: 80.65, price: 1234 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.62033956816883, 13.224658454054055],
            [77.6299795262824, 13.223837251514174],
            [77.63932657914673, 13.221398595718158],
            [77.6480967212904, 13.217416583988348],
            [77.65602347638226, 13.21201220773616],
            [77.66286599397998, 13.205349676199273],
            [77.66841636764885, 13.197631427027027],
            [77.67250595209354, 13.189091975314902],
            [77.67501048736015, 13.179990787981996],
            [77.67585387441196, 13.1706044],
            [77.67501048736015, 13.161218012018004],
            [77.67250595209354, 13.152116824685098],
            [77.66841636764885, 13.143577372972974],
            [77.66286599397998, 13.135859123800728],
            [77.65602347638226, 13.12919659226384],
            [77.6480967212904, 13.123792216011653],
            [77.63932657914673, 13.119810204281842],
            [77.6299795262824, 13.117371548485826],
            [77.62033956816883, 13.116550345945946],
            [77.61069961005526, 13.117371548485826],
            [77.60135255719094, 13.119810204281842],
            [77.59258241504726, 13.123792216011653],
            [77.5846556599554, 13.12919659226384],
            [77.57781314235768, 13.135859123800728],
            [77.57226276868882, 13.143577372972974],
            [77.56817318424412, 13.152116824685098],
            [77.5656686489775, 13.161218012018004],
            [77.5648252619257, 13.1706044],
            [77.5656686489775, 13.179990787981996],
            [77.56817318424412, 13.189091975314902],
            [77.57226276868882, 13.197631427027027],
            [77.57781314235768, 13.205349676199273],
            [77.5846556599554, 13.21201220773616],
            [77.59258241504726, 13.217416583988348],
            [77.60135255719094, 13.221398595718158]
          ]
        ]
      }
    }
  ]
};
