import React, { useState, useEffect, useRef } from "react";

import DashboardSideNav from "../DashboardSideNav/Index";
import Navbar from "../Navbar/Index";

import ProjectionComp from "./ProjectionComp";

function Projection() {
  const [leftPaneHeight, setLeftPaneHeight] = useState(window.innerHeight);
  const [currentOption, setcurrentOption] = useState("Analytics");
  const [isMobile, setIsMobile] = useState(false);
  const [selectedOpt, setSelectedOpt] = useState(4);

  const [selected, setSelected] = useState(0);
  const handleDivClick = (index) => {
    setSelected(index);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const [isLoggedin, setisLoggedin] = useState(false);
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    // Check if there's a user ID and session LS in localStorage
    const userIdFromLocalStorage = localStorage.getItem("user_id");
    const sessionLSFromLocalStorage = localStorage.getItem("sessionLS");

    if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
      // If both exist, set isLoggedin to true and store the user ID in the state
      setisLoggedin(true);
      setUserId(userIdFromLocalStorage);
    }
  }, [isLoggedin]); // Run only once when the component mounts

  useEffect(() => {
    const handleResize = () => {
      setLeftPaneHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sideNavRef = useRef(null);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1020px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addListener(handleResize);

    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, []);

  // if (isMobile) {
  //   return <DashboardMobile />;
  // }

  return (
    <div className="parent font-nunito mt-16">
      <div className="">
        <Navbar
          isLoggedin={isLoggedin}
          setisLoggedin={setisLoggedin}
          setUserId={setUserId}
          userId={userId}
        />{" "}
      </div>
      <div className=" flex flex-row justify-center firstHelper">
        <div className="bg-white helperContainer text-black flex flex-row">
          {/*Left part start */}
          <div
            ref={sideNavRef}
            className=" leftPaneCollectionDash  text-black flex flex-col ml-2 -mt-8 px-4 py-8 pb-12 "
            style={{
              width: "240px",
              borderRadius: "0px",
              backgroundColor: "rgba(244, 243, 245, 1)",
              position: "fixed",
              height: leftPaneHeight,
              overflowY: "auto"
            }}
          >
            <DashboardSideNav
              currentOption={currentOption}
              selectedOpt={selectedOpt}
              setSelectedOpt={setSelectedOpt}
            />
          </div>

          <div className="paddingHelperPhoneDash">
            <ProjectionComp />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projection;
