import React, { useEffect, useReducer, useRef, useState } from "react";
import "./advisor-style.css";
import ReactSlider from "react-slider";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartSection from "./ChartSection";
import GoogleMapComp from "./GoogleMapComp";
import useDebounce from "../../Utils/Debounce";
import { fetchPrdictedRoi } from "../../Api/PredictionApis";
import InvestmentRiskInput from "./InvestmentRiskInput";
import MobileInputSection from "./MobileInputSection";
ChartJS.register(ArcElement, Tooltip, Legend);

export default function SmartAdvisorCompo() {
  const [investmentAmount, setinvestmentAmount] = useState(5000);
  const [timePeriod, setTimePeriod] = useState(2);
  const [selectedRiskCapacity, setSelectedRiskCapacity] = useState("Moderate");
  const [estRoi, setEstRoi] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [locality, setLocality] = useState("");
  const [isMinValErr, setMinValErr] = useState(false);
  const [selectedCity, setSelectedcity] = useState("Bangalore");

  const inputSectionRef = useRef(null);

  const [showStickyInput, setShowStickyInput] = useState(false);
  const handleRiskCapacityChange = (e) => {
    setSelectedRiskCapacity(e.target.value);
  };

  const handleClickOutside = (event) => {
    ClickOutside(event, inputSectionRef, setShowStickyInput);
  };

  const ClickOutside = (event, inputSectionRef, setShowStickyInput) => {
    if (
      inputSectionRef.current &&
      !inputSectionRef.current.contains(event.target)
    ) {
      setShowStickyInput(false);
    }
  };

  const handlecityChange = (e) => {
    setSelectedcity(e.target.value);
  };

  const handleToggleStickySection = () => {
    setShowStickyInput(!showStickyInput);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const chartdata = {
    labels: [
      `Total Returns (${Number(totalReturn).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })})`,
      `Amount to Invest (${investmentAmount < 5000 ? 5000 : investmentAmount})`
    ],
    datasets: [
      {
        //label: 'My First Dataset',
        data: [totalReturn, investmentAmount],
        backgroundColor: ["#D328A7", "#D9D9D9"],
        hoverOffset: 4
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      tooltip: {
        enabled: true
      }
    }
  };

  // console.log(investmentAmount,timePeriod,selectedRiskCapacity);

  const getPrdictedRoi = () => {
    fetchPrdictedRoi(
      investmentAmount,
      selectedRiskCapacity,
      timePeriod,
      setEstRoi,
      setTotalReturn,
      setLat,
      setLong,
      setLocality,
      selectedCity
      // setMinValErr
    );
  };
  const debounce = useDebounce();
  const debouncedFetchROI = debounce(getPrdictedRoi, 500);

  useEffect(() => {
    debouncedFetchROI();
  }, [investmentAmount, timePeriod, selectedRiskCapacity, selectedCity]);

  console.log(selectedCity);

  return (
    <div className="">
      <div className="sticky-area   sm:hidden">
        <div className="flex justify-center pt-2">
          <button
            className="bg-secondary text-white  px-4 py-2.5 rounded-lg "
            onClick={handleToggleStickySection}
          >
            Fill Investment Inputs
          </button>
        </div>
      </div>

      {/* mobile input section */}
      {showStickyInput && (
        <MobileInputSection
          inputSectionRef={inputSectionRef}
          showStickyInput={showStickyInput}
          setShowStickyInput={setShowStickyInput}
          investmentAmount={investmentAmount}
          setinvestmentAmount={setinvestmentAmount}
          setMinValErr={setMinValErr}
          isMinValErr={isMinValErr}
          handleRiskCapacityChange={handleRiskCapacityChange}
          selectedRiskCapacity={selectedRiskCapacity}
          timePeriod={timePeriod}
          setTimePeriod={setTimePeriod}
          selectedCity={selectedCity}
          handlecityChange={handlecityChange}
        />
      )}

      <div className="text-center w-full flex items-center justify-center sm:justify-between  px-1 sm:px-2.5  pb-4">
        <p className="text-2xl sm:text-base font-medium text-center sm:text-left">
          Smart Advisor
        </p>

        <select
          value={selectedCity}
          onChange={handlecityChange}
          className="border border-lightgray px-5 py-1 rounded-lg hidden sm:block"
        >
          <option value="Bangalore">Bengaluru</option>
          <option value="Mumbai">Mumbai</option>
        </select>
      </div>

      <div className="profileSection">
        <div className="innerPart">
          <div className="topPart tab-shadow flex flex-col gap-5">
            <p className="text-lg font-semibold text-blue text-start">
              A smarter approach to investment advice
            </p>

            <div className="flex md:flex-row flex-col  gap-5 w-full">
              {/* left Section */}

              <div className="hidden sm:block sm:w-[45%]">
                <InvestmentRiskInput
                  investmentAmount={investmentAmount}
                  setinvestmentAmount={setinvestmentAmount}
                  setMinValErr={setMinValErr}
                  isMinValErr={isMinValErr}
                  handleRiskCapacityChange={handleRiskCapacityChange}
                  selectedRiskCapacity={selectedRiskCapacity}
                  timePeriod={timePeriod}
                  setTimePeriod={setTimePeriod}
                />
              </div>
              {/* right section */}
              <div className="flex flex-col gap-5 md:w-[55%]">
                <div className="flex flex-col   gap-4 p-2.5 border border-lightgray rounded-lg">
                  <p className="text-lg font-semibold text-blue text-start">
                    Investment Returns Overview
                  </p>

                  <ChartSection
                    data={chartdata}
                    options={options}
                    investmentAmount={investmentAmount}
                    timePeriod={timePeriod}
                    estRoi={estRoi}
                    totalReturn={totalReturn}
                  />
                </div>

                <div className="flex flex-col gap-4 p-2.5 border border-lightgray rounded-lg">
                  <p className="text-lg font-semibold text-blue text-start">
                    Best locality to invest in: {locality && locality}
                  </p>
                  <GoogleMapComp lat={lat} long={long} locality={locality} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
