import React, { useState, useEffect, useRef } from "react";

import "./map.css";

import BarChart from "../Barchart.js/Index";

import ProjectionFilter from "../ProjectionFilter/Index";

import {
  generateQuarterLabels,
  getPayloadWithQuarters,
  getRandomColor
} from "../../Utils/projectionUtils";
import { TfiFilter } from "react-icons/tfi";
import {
  fetchALMLLocations,
  getPredictedData,
  TrackLocationAvailability
} from "../../Api/ProjectionAPI";
import { ColorRing } from "react-loader-spinner";

import useDebounce from "../../Utils/Debounce";

import Map from "./Map";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { ALML_API_URL } from "../../Utils/urls";
import MapFilterMobile from "./MapFilterMobile";

export default function ProjectionMapComp() {
  const [mainCity, setMainCity] = useState("Bangalore");
  const [selectedCities, setSelectedCities] = useState("100 Feet Ring Road");

  const [showMobFilter, setshowMobFilter] = useState(false);
  const [projectionLocations, setProjectionLocation] = useState({});
  const [isAreaLoading, setAreaLoading] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [mainCitiList, setMainCityList] = useState([]);
  const [localityArr, setLocalityArr] = useState([]);

  //Main City Coordinates
  const [cityLatitude, setCityLatitude] = useState("");
  const [cityLongitude, setCityLongitude] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const userId = localStorage.getItem("user_id");
  const userName = localStorage.getItem("user_name");
  //const [filteredAreas, setFilteredArea] = useState([]);

  const togglePopup = () => {
    setshowMobFilter(!showMobFilter);
  };
  const d = new Date();
  let currentYear = d.getFullYear();

  //State for api
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [selectedQuarter, setSelectedQuarter] = useState("Jan-Mar");
  const [predictPayload, setPredictPayload] = useState([
    {
      location: selectedCities[0],
      quarter: selectedQuarter || "Jan-Mar",
      year: Number(selectedYear)
    }
  ]);

  const [predictResult, setPredictResult] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [debouncedSearchInput, setDebouncedSearchInput] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);
  const [quarterLabels, setQuarterLabels] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const accessToken = localStorage.getItem("accestoken");
  const debounce = useDebounce();
  const dropdownRef = useRef(null);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this threshold as needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const HandleCloseCityDrop = (event) => {
    event.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLocationSelect = (location) => {
    setSelectedCities(location);
    setIsOpen(false);
  };
  useEffect(() => {
    fetchALMLLocations(setAreaLoading, accessToken, setProjectionLocation);
  }, []);

  useEffect(() => {
    //19.0760, 72.8777;
    if (mainCity === "Bangalore") {
      setCityLatitude(12.9716);
      setCityLongitude(77.5946);
    } else if (mainCity === "Mumbai") {
      setCityLatitude(19.076);
      setCityLongitude(72.8777);
    }
  }, [cityLatitude, cityLongitude, mainCity]);

  //console.log(cityLatitude, cityLongitude, mainCity);
  console.log(isOpen);

  useEffect(() => {
    setMainCityList(Object.keys(projectionLocations));
  }, [projectionLocations]);

  const filteredAreas =
    projectionLocations &&
    Object.keys(projectionLocations).length > 0 &&
    projectionLocations[mainCity].filter((item) =>
      item.toLowerCase().includes(debouncedSearchInput.toLowerCase())
    );

  useEffect(() => {
    if (filteredAreas && filteredAreas.length > 0) {
      setSelectedCities([filteredAreas[0]]);
    }
  }, [mainCity]);

  // Handle the search input change
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    debounce((debouncedValue) => {
      setDebouncedSearchInput(debouncedValue);
    }, 500)(value); // 500ms delay
  };

  const createPayload = (
    mainCity,
    projectionLocations,
    selectedQuarter,
    selectedYear
  ) => {
    // Retrieve locations for the given mainCity
    const locations = projectionLocations[mainCity] || [];

    const payload = locations.map((location) => ({
      location: location,
      quarter: selectedQuarter,
      year: selectedYear
    }));

    return payload;
  };

  useEffect(() => {
    if (Object.keys(projectionLocations).length > 0) {
      const localityArray = createPayload(
        mainCity,
        projectionLocations,
        selectedQuarter,
        selectedYear
      );
      setLocalityArr(localityArray);
    }
  }, [mainCity, projectionLocations]);

  const fetchMapData = () => {
    fetch(`${ALML_API_URL}/geomap`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        // predictions: localityArr,
        city: mainCity,
        // priceType: "all",
        year: selectedYear,
        quarter: selectedQuarter
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        setLoading(false);
        setPredictResult(resp);

        // console.log(data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was a problem with the request:", error);
        // Handle error
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchMapData();
  }, [selectedQuarter, selectedYear]);

  if (isMobile) {
    // Render mobile view
    return (
      <>
        <div className=" -mt-6 flex flex-row justify-between marginAnalHelp">
          <div className="flex flex-col justify-center">
            {/* <IoMdArrowBack style={{height:"26px",width:"26px"}} /> */}
          </div>

          <div className="anaMobText">Price Projections</div>
          <div
            className="flex flex-col justify-center cursor-pointer"
            onClick={togglePopup}
          >
            <TfiFilter style={{ fontSize: "20px" }} />
          </div>
        </div>
        <div className=" flex flex-col gap-[15px] mt-[30px]">
          {/* headAnalytics */}
          <div className="text-md">
            Property Price Comparison Based on Micro Market
          </div>

          {/* mobile-view */}
          <div className="shadow-lg overflow-x-auto px-4 py-2 rounded-md mb-10">
            <div
              className=""
              style={{ height: "460px", width: "700px", overflowX: "auto" }}
            >
              {cityLatitude && cityLongitude ? (
                <Map
                  cityLatitude={cityLatitude}
                  cityLongitude={cityLongitude}
                  predictResult={predictResult}
                  selectedCities={selectedCities}
                />
              ) : (
                <p>Loading map...</p> // Or any loading state you want
              )}
            </div>
          </div>
        </div>

        {showMobFilter && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="h-full w-full">
              <MapFilterMobile
                setshowMobFilter={setshowMobFilter}
                showMobFilter={showMobFilter}
                togglePopup={togglePopup}
                selectedCities={selectedCities}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                handleLocationSelect={handleLocationSelect}
                selectedQuarter={selectedQuarter}
                setSelectedQuarter={setSelectedQuarter}
                setSelectedCities={setSelectedCities}
                mainCity={mainCity}
                setMainCity={setMainCity}
                searchInput={searchInput}
                handleSearchChange={handleSearchChange}
                filteredAreas={filteredAreas}
                mainCitiList={mainCitiList}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                dropdownRef={dropdownRef}
              />
            </div>
          </div>
        )}
      </>
    );
  }
  const handleSelectCity = (city) => {
    setMainCity(city);
    setIsOpen(false); // Close the dropdown after selection
  };
  return (
    <div>
      <div className=" flex flex-col font-nunito ">
        <div className=" text-start anaText">Price Projections</div>
        <div className=" mt-[30px] flex flex-col parentBorderHelperClass p-[20px]">
          <div className=" flex flex-row justify-between">
            <div className=" priceCompText">
              <div className="flex gap-1 items-center py-2">
                <p> Property Price Comparison Based on Locality</p>

                {isLoading ? (
                  <ColorRing
                    visible={true}
                    height="30"
                    width="30"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                      "#d328a7",
                      "#d328a7",
                      "#d328a7",
                      "#d328a7",
                      "#d328a7",
                      "#d328a7"
                    ]}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className=" flex flex-row justify-between mt-[10px]">
            <div className=" flex flex-col" style={{ width: "100%" }}>
              <div className=" flex flex-row gap-[10px] items-center mb-4">
                <select
                  name=""
                  value={mainCity}
                  className="px-[2px] py-[4px] rounded-md"
                  style={{ border: "1px solid #CCCCCC" }}
                  onChange={(e) => setMainCity(e.target.value)}
                >
                  <option value="" disabled>
                    Select City
                  </option>
                  {mainCitiList.length > 0 &&
                    mainCitiList.map((city) => (
                      <option value={city}>{city}</option>
                    ))}
                </select>

                <div className="relative w-64">
                  <div
                    className="flex items-center justify-between gap-2 px-[2px] py-[4px] rounded-md cursor-pointer"
                    style={{ border: "1px solid #CCCCCC" }}
                    onClick={HandleCloseCityDrop}
                  >
                    <div className="">
                      {selectedCities.length > 25
                        ? selectedCities.substring(24, 0) + "..."
                        : selectedCities || "Select a city"}{" "}
                    </div>
                    <MdOutlineKeyboardArrowDown
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    />
                  </div>

                  {isOpen && (
                    <div
                      style={{ border: "1px solid #CCCCCC" }}
                      className="absolute w-full bg-white border border-t-0 z-[9999] rounded-md max-h-60 overflow-y-auto"
                      ref={dropdownRef}
                    >
                      {/* Sticky search input */}
                      <div className="px-3 py-2">
                        <input
                          type="text"
                          className="w-full px-2 py-1 border border-gray-300 rounded-md sticky top-0 bg-white z-10"
                          placeholder="Search areas..."
                          value={searchInput}
                          onChange={handleSearchChange}
                        />
                      </div>

                      {/* Filtered options */}
                      <div className="flex flex-col gap-1 items-start justify-start max-h-[calc(100vh-120px)] overflow-y-auto">
                        {filteredAreas.map((city, index) => (
                          <div
                            key={index}
                            className="px-3 py-2 cursor-pointer hover:bg-gray-100 text-left"
                            onClick={() => handleLocationSelect(city)}
                          >
                            {city}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <select
                  name=""
                  value={selectedQuarter}
                  className="px-[2px] py-[4px] rounded-md"
                  style={{ border: "1px solid #CCCCCC" }}
                  onChange={(e) => setSelectedQuarter(e.target.value)}
                >
                  <option value="" disabled>
                    Select Quarter
                  </option>
                  <option value="Jan-Mar">January-March</option>
                  <option value="Apr-Jun">April-June</option>
                  <option value="Jul-Sep">July-September</option>
                  <option value="Oct-Dec">October-December</option>
                </select>

                <select
                  className="px-[2px] py-[4px] rounded-md "
                  style={{ border: "1px solid #CCCCCC" }}
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(Number(e.target.value))}
                >
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>\
                  <option value="2030">2030</option>
                </select>

                {/* <select name="" 
                   className='px-[2px] py-[4px] rounded-md' 
                   style={{border:"1px solid #CCCCCC"}}
                  value={"Residential"} disabled >
                    <option value="">Asset Types</option>
                      <option value="Residential">Residential</option>
                    
                 </select> */}

                <div
                  className="flex flex-row justify-between px-[12px] py-[4px] rounded-md border gap-2"
                  style={{ border: "1px solid #CCCCCC" }}
                >
                  <div className="text-[#686868]">Residential</div>
                </div>
              </div>

              <div
                className=" flex flex-col justify-center"
                style={{ height: "100%" }}
              >
                {cityLatitude && cityLongitude ? (
                  <Map
                    cityLatitude={cityLatitude}
                    cityLongitude={cityLongitude}
                    predictResult={predictResult}
                    selectedCities={selectedCities}
                  />
                ) : (
                  <p>Loading map...</p> //
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
