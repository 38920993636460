import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.fullscreen";
import "leaflet.fullscreen/Control.FullScreen.css";

export default function Map({
  cityLatitude,
  cityLongitude,
  predictResult,
  selectedCities
}) {
  const mapRef = useRef(null);
  const [selectedCityTooltip, setSelectedCityTooltip] = useState(null);
  const [selectedCityCircle, setSelectedCityCircle] = useState(null);

  const [selectedLat, setSelectedLat] = useState("");
  const [selectedLong, setSelectedLong] = useState("");

  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map("map", {
        fullscreenControl: true
      }).setView([cityLatitude, cityLongitude], 12);

      mapRef.current = map;

      // Add a tile layer
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution: "© OpenStreetMap contributors"
      }).addTo(map);

      // Add markers for each location
      predictResult.forEach((state) => {
        const {
          avg_price,
          location,
          latitude,
          longitude,
          max_price,
          min_price
        } = state;

        const circleMarker = L.circleMarker([latitude, longitude], {
          radius: 5,
          color: "#00008B",
          fillColor: "#00008B",
          fillOpacity: 0.9,
          weight: 1
        })
          .addTo(map)
          .bindTooltip(
            `<div style="font-size:17px;margin-bottom:10px">${location}</div>
             <div>Maximum Price : ${max_price.toLocaleString("en-US", {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2
             })}</div>
             <div style="color:#d328a7"> Average Price: ${avg_price.toLocaleString(
               "en-US",
               {
                 minimumFractionDigits: 2,
                 maximumFractionDigits: 2
               }
             )}</div>
             <div>Minimum Price : ${min_price.toLocaleString("en-US", {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2
             })}</div>`,
            {
              permanent: false,
              direction: "top",
              className: "leaflet-tooltip-custom",
              opacity: 0.9
            }
          );

        let hoverCircle;

        // On marker hover, display a larger circle
        circleMarker.on("mouseover", () => {
          hoverCircle = L.circle([latitude, longitude], {
            radius: 2000,
            color: "blue",
            fillColor: "blue",
            fillOpacity: 0.2,
            weight: 1
          }).addTo(map);
        });

        // Remove the larger circle on mouseout
        circleMarker.on("mouseout", () => {
          if (hoverCircle) {
            map.removeLayer(hoverCircle);
            hoverCircle = null;
          }
        });

        // Highlight the selected city with a larger circle
        if (selectedCities === location) {
          map.setView([latitude, longitude], 12);
          // Remove the previously selected circle if it exists
          if (selectedCityCircle) {
            map.removeLayer(selectedCityCircle);
          }

          // Create a larger circle (2 km radius)
          const highlightCircle = L.circle([latitude, longitude], {
            radius: 2000,
            color: "blue",
            fillColor: "blue",
            fillOpacity: 0.2,
            weight: 1
          }).addTo(map);

          setSelectedCityCircle(highlightCircle);

          // Show tooltip for the selected city
          setSelectedCityTooltip(
            L.tooltip({
              permanent: true,
              direction: "top",
              className: "leaflet-tooltip-custom",
              opacity: 0.9
            })
              .setLatLng([latitude, longitude])
              .setContent(
                `<div style="font-size:17px;margin-bottom:10px">${location}</div>
                 <div>Maximum Price : ${max_price.toLocaleString("en-US", {
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2
                 })}</div>
                 <div style="color:#d328a7"> Average Price: ${avg_price.toLocaleString(
                   "en-US",
                   {
                     minimumFractionDigits: 2,
                     maximumFractionDigits: 2
                   }
                 )}</div>
                 <div>Minimum Price : ${min_price.toLocaleString("en-US", {
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2
                 })}</div>`
              )
              .addTo(map)
          );
          //map.setView([latitude, longitude], 12);
        }
      });
    } else {
      let map = mapRef.current.setView([cityLatitude, cityLongitude], 12);

      // Remove previous selected city circle and tooltip
      if (selectedCityCircle) {
        map.removeLayer(selectedCityCircle);
      }
      if (selectedCityTooltip) {
        map.removeLayer(selectedCityTooltip);
      }

      // Add markers for each location
      predictResult.forEach((state) => {
        const {
          avg_price,
          location,
          latitude,
          longitude,
          max_price,
          min_price
        } = state;

        const circleMarker = L.circleMarker([latitude, longitude], {
          radius: 5,
          color: "#00008B",
          fillColor: "#00008B",
          fillOpacity: 0.9,
          weight: 1
        })
          .addTo(map)
          .bindTooltip(
            `<div style="font-size:17px;margin-bottom:10px">${location}</div>
             <div>Maximum Price : ${max_price.toLocaleString("en-US", {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2
             })}</div>
             <div style="color:#d328a7"> Average Price: ${avg_price.toLocaleString(
               "en-US",
               {
                 minimumFractionDigits: 2,
                 maximumFractionDigits: 2
               }
             )}</div>
             <div>Minimum Price : ${min_price.toLocaleString("en-US", {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2
             })}</div>`,
            {
              permanent: false,
              direction: "top",
              className: "leaflet-tooltip-custom",
              opacity: 0.9
            }
          );

        let hoverCircle;

        // On marker hover, display a larger circle
        circleMarker.on("mouseover", () => {
          hoverCircle = L.circle([latitude, longitude], {
            radius: 2000,
            color: "blue",
            fillColor: "blue",
            fillOpacity: 0.2,
            weight: 1
          }).addTo(map);
        });

        // Remove the larger circle on mouseout
        circleMarker.on("mouseout", () => {
          if (hoverCircle) {
            map.removeLayer(hoverCircle);
            hoverCircle = null;
          }
        });

        // Highlight the selected city
        if (selectedCities === location) {
          map.setView([latitude, longitude], 12);
          // Create a larger circle (2 km radius) for the selected city
          const highlightCircle = L.circle([latitude, longitude], {
            radius: 2000,
            color: "blue",
            fillColor: "blue",
            fillOpacity: 0.2,
            weight: 1
          }).addTo(map);

          setSelectedCityCircle(highlightCircle);

          // Show tooltip for the selected city
          setSelectedCityTooltip(
            L.tooltip({
              permanent: true,
              direction: "top",
              className: "leaflet-tooltip-custom",
              opacity: 0.9
            })
              .setLatLng([latitude, longitude])
              .setContent(
                `<div style="font-size:17px;margin-bottom:10px">${location}</div>
                 <div>Maximum Price : ${max_price.toLocaleString("en-US", {
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2
                 })}</div>
                 <div style="color:#d328a7"> Average Price: ${avg_price.toLocaleString(
                   "en-US",
                   {
                     minimumFractionDigits: 2,
                     maximumFractionDigits: 2
                   }
                 )}</div>
                 <div>Minimum Price : ${min_price.toLocaleString("en-US", {
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2
                 })}</div>`
              )
              .addTo(map)
          );
        }
      });
    }
  }, [cityLatitude, cityLongitude, predictResult, selectedCities]);

  return (
    <div id="map" style={{ height: "500px", width: "100%", zIndex: "5" }}></div>
  );
}
