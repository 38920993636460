import React, { useState, useRef, useEffect } from "react";
import PropImage from "../../../Assets/propImage.png";
import "./Index.css";
import { IconContext } from "react-icons";
import { BiShareAlt } from "react-icons/bi";
import { FaFacebook, FaRegHeart, FaWhatsapp } from "react-icons/fa";

import { MdOutlineLocationOn, MdOutlineWhatsapp } from "react-icons/md";
import { Link, Navigate } from "react-router-dom";
import { IoArrowUp } from "react-icons/io5";
import copyClip from "../../../Assets/socialHandles/copyClipboard.svg";
import facebook from "../../../Assets/socialHandles/facebook.svg";
import whatsapp from "../../../Assets/socialHandles/whatsapp.svg";
import X from "../../../Assets/socialHandles/x.svg";
import linkedIn from "../../../Assets/socialHandles/linkedIn.svg";
import InterestForm from "../../InterestForm/Index";
import { FaHeart } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import SuccessAlert from "../../Alert/SuccessAlert";
import { ApiBaseUrl } from "../../../Utils/urls";
import { RiFacebookFill, RiTwitterXFill } from "react-icons/ri";
import { TiSocialLinkedin } from "react-icons/ti";
import { AiOutlineLink } from "react-icons/ai";
import { PropertImageBaseUrl } from "../../../Utils/urls";
import { formatForUrl } from "../../../Utils/formatSinglePropertyUrl";

function PropertyCard(props) {
  const [isShareSelected, setisShareSelected] = useState(false);
  const shareRef = useRef(null);
  const excludeRef = useRef(null);
  const [isCopy, setisCopy] = useState(false);
  // const [isnotWishlist, setisnotWishlist] = useState(1);
  const navigate = useNavigate();
  const [alreadyEnquired, setalreadyEnquired] = useState(false);
  const [imageUrl, setimageUrl] = useState("");

  useEffect(() => {
    setimageUrl(props.image);
  }, []);

  //console.log(props.tokensSold, props.totalTokens, props.prop_sale_type);
  const constructUrl = () => {
    let url = `/${formatForUrl(props.place)}/${formatForUrl(props.name)}/${
      props.id
    }`;
    if (props.propertSaleType === "RESALE") {
      url = `/${formatForUrl(props.place)}/${formatForUrl(props.name)}/RESALE/${
        props.id
      }`;
    }
    return url;
  };

  const handleCardClick = () => {
    const url = constructUrl();
    navigate(url);
  };

  const share = (platform) => {
    switch (platform) {
      case "copyToClipboard":
        copyToClipboard(props.id);
        break;
      case "shareFacebook":
        shareFacebook();
        break;
      case "shareWhatsapp":
        shareWhatsapp();
        break;
      case "shareTwitter":
        shareTwitter();
        break;
      case "shareLinkedIn":
        shareLinkedIn();
        break;
      default:
        break;
    }
  };

  const copyToClipboard = (propertyId) => {
    const url = constructUrl(props.place, props.name, propertyId);
    setisCopy(1);
    const el = document.createElement("textarea");
    el.value = `${window.location.origin}${url}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const shareFacebook = () => {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(window.location.href) +
        "&quote=Hi! Have a look at this awesome fractional property investment opportunity with PropFTX"
    );
  };

  // const shareWhatsapp = () => {

  //     window.open('https://api.whatsapp.com/send?text=' + encodeURIComponent('Hi! Have a look at this awesome fractional property investment opportunity with PropFTX: ') + encodeURIComponent(window.location.href));
  // };

  const shareWhatsapp = () => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent(
      "Hi! Have a look at this awesome fractional property investment opportunity with PropFTX: "
    );
    const favicon = encodeURIComponent("https://app.propftx.com/favicon.ico");

    const shareURL = `https://api.whatsapp.com/send?text=${text} ${url}&amp;data=%7B%22base%22%3A%22${favicon}%22%7D`;

    window.open(shareURL);
  };

  const shareTwitter = () => {
    window.open(
      "https://twitter.com/intent/tweet?url=" +
        encodeURIComponent(window.location.href) +
        "&text=Hi! Have a look at this awesome fractional property investment opportunity with PropFTX"
    );
  };

  const shareLinkedIn = () => {
    window.open(
      "https://www.linkedin.com/sharing/share-offsite/?url=" +
        encodeURIComponent(window.location.href) +
        "&summary=Hi! Have a look at this awesome fractional property investment opportunity with PropFTX"
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        shareRef.current &&
        !shareRef.current.contains(event.target) &&
        excludeRef.current &&
        !excludeRef.current.contains(event.target)
      ) {
        setisShareSelected(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [isLoggedin, setisLoggedin] = useState(false);
  const [userId, setUserId] = useState(null);
  const [accessToken, setaccessToken] = useState(null);
  const [currentPropId, setcurrentPropId] = useState(0);
  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem("user_id");
    const sessionLSFromLocalStorage = localStorage.getItem("sessionLS");
    const AccessTokenFromLocalStorage = localStorage.getItem("accestoken");
    if (userIdFromLocalStorage && AccessTokenFromLocalStorage) {
      setisLoggedin(true);
      setUserId(userIdFromLocalStorage);
      setaccessToken(AccessTokenFromLocalStorage);
    }
  }, [isLoggedin]);

  const EnquiryAPI = (propertyId) => {
    return async () => {
      if (!props.isLoggedin) {
        props.setIsInterestFormOpen(1);
      } else {
        props.setshowAlert(true);
        setTimeout(() => {
          props.setshowAlert(false);
        }, 2000);

        try {
          if (userId) {
            const response = await fetch(
              `${ApiBaseUrl}/fr-property-enquiry/add-enquiry-fr-property`,
              {
                method: "POST",
                body: JSON.stringify({
                  user_id: userId,
                  property_id: propertyId
                })
              }
            );
            if (response.ok) {
              setalreadyEnquired(true);
            }
            if (!response.ok) {
              throw new Error("Failed to add property to wishlist");
            }
          } else {
            console.error("userId or accessToken is missing");
          }
        } catch (error) {
          console.error("Error adding property to wishlist:", error);
        }
      }
      props.setcurrentInterestPropId(propertyId);
    };
  };

  const [isEmailVerified, setisEmailVerified] = useState(false);
  const [isKYCVerified, setisKYCVerified] = useState(false);
  const [showEmailUpdateAlert, setShowEmailUpdateAlert] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId && accessToken) {
          const response = await fetch(
            `${ApiBaseUrl}/fr-user/fetch-profile-and-kyc?user_id=${userId}`,
            {
              headers: {
                Authorization: accessToken,
                "Content-Type": "application/json"
              }
            }
          );
          if (!response.ok) {
            throw new Error("Failed to fetch profile and KYC details");
          }
          const data = await response.json();
          if (
            data.data.profile.email === null ||
            data.data.profile.email === undefined
          ) {
            setisEmailVerified(false);
          } else {
            setisEmailVerified(true);
          }
          if (
            data.data.kyc.kyc_status === "REQUESTED" ||
            data.data.kyc.kyc_status === "APPROVED"
          ) {
            setisKYCVerified(true);
          } else {
            setisKYCVerified(false);
          }
        }
      } catch (error) {
        console.error("Error fetching profile and KYC details:", error);
      }
    };

    fetchData();
  }, [userId, accessToken]);

  const handleEnquiry = (propertyId) => {
    return () => {
      if (!isEmailVerified && isLoggedin) {
        props.setisnotEmailVerified(true);
      } else if (!props.has_enquired) {
        const enquiryFunction = EnquiryAPI(propertyId);
        enquiryFunction(); // Invoke the returned function
      }
    };
  };

  const callWishlistAPI = async (propsId) => {
    try {
      if (userId && accessToken) {
        const response = await fetch(
          `${ApiBaseUrl}/fr-user/wishlist-property`,
          {
            method: "POST",
            headers: {
              Authorization: accessToken,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              user_id: userId,
              property_id: propsId
            })
          }
        );
        if (response.ok) {
          props.fetchProperties();
          //props.setfetchAPIHelper(true); //wishlist
        }
        // if (!response.ok) {
        //     throw new Error('Failed to add property to wishlist');
        // }
      }
    } catch (error) {
      console.error("Error adding property to wishlist:", error);
    }
  };

  const toggleWishlist = () => {
    props.setlandingPageRefreshHelp(!props.landingPageRefreshHelp);
    const userID = localStorage.getItem("user_id");
    props.setisnotUserIdPresent(!userID);
    // if (!isnotWishlist) {
    //     setisnotWishlist(1);
    // } else {
    //     setisnotWishlist(0);
    // }
  };

  const handleWishlistClick = (propId) => {
    // toggleWishlist();//wishlist
    if (!isLoggedin) {
      navigate("/login");
    }
    callWishlistAPI(propId);
  };

  //console.log(imageUrl ? `${PropertImageBaseUrl}/${imageUrl}` : "Image URL is empty");

  return (
    <div className="flex flex-row justify-center widthHelperForTab">
      <div className="cardProp py-2 px-2 text-black flex flex-col font-nunito relative">
        {props.prop_sale_type == "SOLD OUT" && (
          <div class="wrap">
            <span class="ribbon6">Sold Out</span>
          </div>
        )}

        <div className="text-black flex flex-row justify-center">
          {imageUrl && (
            <img
              src={`${PropertImageBaseUrl}/${imageUrl}`}
              className="cardImageCollect w-full h-full cover rounded-xl"
              alt="property"
            />
          )}
        </div>
        <div className="-mt-36 text-black flex flex-col ">
          <div className="text-black flex flex-row justify-between pl-6 pr-6 -mt-2 ">
            {props.prop_sale_type !== "SOLD OUT" && (
              <div className="ageProp text-[11px] text-white pt-0.5 -ml-4">
                {props.prop_sale_type === "SOLD OUT"
                  ? "Sold Out"
                  : props.sale_status === "CREATED"
                  ? "Coming Soon"
                  : props.propertyPhase}
              </div>
            )}

            <div
              className="shareProp ml-auto flex flex-row justify-between pl-1.5 pr-1.5 -mr-4"
              style={{ cursor: "pointer" }}
            >
              <div
                className="mt-1"
                onClick={() => setisShareSelected(!isShareSelected)}
              >
                <IconContext.Provider
                  value={{ color: "white", className: "w-4 h-4" }}
                >
                  <div>
                    <BiShareAlt />
                  </div>
                </IconContext.Provider>
              </div>
              <div
                className="mt-1"
                ref={excludeRef}
                onClick={() => {
                  handleWishlistClick(props.id);
                  // props.setrefreshPage(!props.refreshPage);//wishlist
                }}
              >
                {!props.isLoggedin ? (
                  <>
                    <IconContext.Provider
                      value={{ color: "white", className: "w-4 h-4" }}
                    >
                      <div>
                        <FaRegHeart />
                      </div>
                    </IconContext.Provider>
                  </>
                ) : props.is_wishlisted ? (
                  <IconContext.Provider
                    value={{ color: "red", className: "w-4 h-4" }}
                  >
                    <div>
                      <FaHeart />
                    </div>
                  </IconContext.Provider>
                ) : (
                  <IconContext.Provider
                    value={{ color: "white", className: "w-4 h-4" }}
                  >
                    <div>
                      <FaRegHeart />
                    </div>
                  </IconContext.Provider>
                )}
              </div>
            </div>
          </div>
          <div className="propTypeProphelper text-black flex flex-row justify-between pl-6 h-8">
            <div className="propTypeProp bg-blue text-xs pl-2 pr-2 mt-2 -ml-4">
              {" "}
              {props.type}{" "}
            </div>
            {isShareSelected && (
              <div
                ref={shareRef}
                className="flex flex-col text-white -mt-[90px] mr-2 w-44 h-[170px] z-50 bg-white rounded-lg"
                style={{
                  boxShadow: "2.8125px 2.8125px 16.875px 0px rgba(0, 0, 0, 0.1)"
                }}
              >
                <div className="pr-1 pl-2 py-2 flex flex-col gap-4">
                  <div
                    className="flex flex-row justify-start gap-1 copyToClipboard cursor-pointer "
                    onClick={() => share("copyToClipboard")}
                  >
                    <div className="flex flex-row justify-center text-black w-6">
                      <AiOutlineLink className="socialMediaIcon linkicon" />
                    </div>
                    <div className=" text-black text-[12px] flex flex-col justify-center font-semibold w-[100px] text-start">
                      {isCopy ? "Link Copied!" : "Copy Link"}
                    </div>
                  </div>

                  <div
                    className="flex flex-row justify-start gap-1 shareFacebook cursor-pointer "
                    onClick={() => share("shareFacebook")}
                  >
                    <div className="flex flex-row justify-center text-black w-6">
                      <RiFacebookFill className="socialMediaIcon fbicon" />
                    </div>
                    <div className=" text-black text-[12px] flex flex-col justify-center font-semibold w-[110px] text-start">
                      Share on Facebook
                    </div>
                  </div>

                  <div
                    className="flex flex-row justify-start gap-1 shareWhatsapp cursor-pointer"
                    onClick={() => share("shareWhatsapp")}
                  >
                    <div className="flex flex-row justify-center text-black w-6">
                      <MdOutlineWhatsapp className="socialMediaIcon whatsappicon" />
                    </div>
                    <div className=" text-black text-[12px] flex flex-col justify-center font-semibold text-start">
                      Share on Whatsapp
                    </div>
                  </div>

                  <div
                    className="flex flex-row justify-start gap-1 shareTwitter cursor-pointer"
                    onClick={() => share("shareTwitter")}
                  >
                    <div className="flex flex-row justify-center text-black w-6">
                      <RiTwitterXFill className="socialMediaIcon twittericon" />
                    </div>
                    <div className=" text-black text-[12px] flex flex-col justify-center font-semibold text-start">
                      Share on X
                    </div>
                  </div>

                  <div
                    className="flex flex-row justify-start gap-1 shareLinkedIn cursor-pointer"
                    onClick={() => share("shareLinkedIn")}
                  >
                    <div className="flex flex-row justify-center text-black w-6">
                      <TiSocialLinkedin className="socialMediaIcon linkedinicon" />
                    </div>
                    <div className=" text-black text-[12px] flex flex-col justify-center font-semibold text-start">
                      Share on Linkedin
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="text-black mt-2 flex flex-col p-1">
            <Link
              to={`/${formatForUrl(props.place)}/${formatForUrl(props.name)}/${
                props.id
              }`}
            >
              <div className="text-black text-start text-base font-bold">
                {props.name}
              </div>
            </Link>

            <div className="flex flex-row pt-1">
              <div className="locationImageCollectCard">
                <IconContext.Provider
                  value={{ color: "black", className: "w-4 h-4" }}
                >
                  <div>
                    <MdOutlineLocationOn />
                  </div>
                </IconContext.Provider>
              </div>
              <div className=" text-black text-sm pl-1 font-normal">
                {props.exactLocation}, {props.place}
              </div>
            </div>
          </div>

          <div className="collectCardDetail text-black flex flex-row justify-between mt-2 p-1">
            <div className="text-black flex flex-col">
              <div className="text-[rgba(93, 92, 92, 1)] text-black text-xs ml-4 font-medium font-nunito">
                Min Investment
              </div>
              <div className="text-black text-base font-bold pt-1">
                ₹{new Intl.NumberFormat("en-IN").format(props.minInvestment)}
              </div>
            </div>
            <div className="text-black flex flex-col mr-4">
              <div className="text-[rgba(93, 92, 92, 1)] text-xs font-medium">
                Property Value
              </div>
              <div className="text-black text-base font-bold pt-1">
                {props.price < 10000000
                  ? `₹${(props.price / 100000)
                      .toFixed(2)
                      .replace(/\.?0+$/, "")} Lakhs`
                  : `₹${(props.price / 10000000)
                      .toFixed(2)
                      .replace(/\.?0+$/, "")} Cr`}
              </div>
            </div>
          </div>

          <div className="text-black flex flex-row justify-between mt-2 pl-2 pr-2">
            <div className="detailCardCollect text-black flex flex-col pt-1">
              <div className="text-[rgba(93, 92, 92, 1)] text-[10px]">
                Tokens
              </div>
              <div className="text-black text-[14.85px] font-bold">
                {props.totalTokens}
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="detailCardCollect text-black flex flex-col pt-1">
              <div className="text-[rgba(93, 92, 92, 1)] text-[10px] whitespace-nowrap">
                {props.sale_type === "Presale" ? "Est. Yield" : " Gross Yield"}
              </div>
              <div className="text-black text-[14.85px] font-bold">
                {props.grossYield}%
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="detailCardCollect text-black flex flex-col pt-1">
              <div className="text-[rgba(93, 92, 92, 1)] text-[10px] whitespace-nowrap">
                {props.sale_type === "Presale" ? "Target ARR" : " Target IRR"}
              </div>
              <div className="text-black text-[14.85px] font-bold">
                {props.targetIRR}%
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="text-black flex flex-col pl-1 pt-1">
              <div className="text-[rgba(93, 92, 92, 1)] text-[10px] whitespace-nowrap">
                Potential Gain
                {/* {props.sale_type==="Presale"?"Potential Gain":"  Capital Gain"} */}
              </div>
              <div className="flex flex-row justify-center">
                <div className="flex flex-row">
                  <div className="flex flex-col justify-center">
                    <IoArrowUp
                      style={{
                        height: "14px",
                        width: "12px",
                        color: "rgba(47, 223, 153, 1)"
                      }}
                    />
                  </div>
                  <div className="text-black text-[14.85px] font-bold">
                    {props.capitalGain}%
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-black flex flex-col  mt-2 p-1 gap-1">
            <div className="flex flex-row justify-center">
              <div className="tokenSoldProgressbar text-black flex flex-row ">
                {/* 
                <div className='bg-secondary flex flex-row-reverse relative'  style={{ width: `${((props.tokensSold) / props.totalTokens) * 100}%` }}>
                    
                    <div className='absolute tokenIcon bg-navyBlue text-white  flex flex-col justify-center'>
                        <div className='fontTokenPB font-bold bg-black rounded-xl w-[13px]'  >T</div>
                    </div>
                    </div> */}

                <div
                  className="flex progressLength flex-row-reverse"
                  style={{
                    width: `${(props.tokensSold / props.totalTokens) * 100}%`
                  }}
                >
                  <div className="tokenIcon bg-navyBlue text-white  flex flex-col justify-center">
                    <div
                      className="fontTokenPB font-bold bg-black rounded-xl w-[13px]"
                      style={{ marginLeft: "15px" }}
                    >
                      T
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-black text-[10px] progressText">
              {
                <span className="font-normal text-secondary">
                  {props.tokensSold}
                </span>
              }{" "}
              out of{" "}
              {
                <span className="text-secondary font-bold">
                  {props.totalTokens}
                </span>
              }{" "}
              tokens sold
            </div>
          </div>
          <div className="launchingCardCollect  text-white text-lg font-bold mt-4">
            <div
              className={`enquireNow rounded-lg flex flex-col justify-center font-semibold py-2 w-[150px] h-[36px] cursor-pointer ${
                alreadyEnquired || props.has_enquired ? "fadedCardButton" : ""
              }`}
              onClick={handleEnquiry(props.id)}
            >
              {props.isLoggedin
                ? alreadyEnquired || props.has_enquired
                  ? "Interest Saved"
                  : "Express Interest"
                : "Enquire Now"}
            </div>
            <div
              className=" explore text-black border  px-6 rounded-lg  flex flex-col justify-center font-semibold py-1 w-32 cursor-pointer  h-[36px]"
              onClick={() => handleCardClick(props.id)}
            >
              Explore
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyCard;
