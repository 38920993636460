import React, { useEffect, useRef, useState } from "react";
import "./paymentsche-mobile.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ClickOutside,
  DecrementToken,
  IncrementToken,
  ProceedToPurchase,
  calculateTotalPrice
} from "../../Utils/tokenCalculation";
import StickyPriceComponent from "./ResponsiveComponents/StickyPriceComponent";
import { getUserDetails } from "../../Api/ProfileApi";
import { getPropertyDetails } from "../../Api/PropertyApi";
import { ApiBaseUrl } from "../../Utils/urls";
import { getPaymentScheduleData } from "../../Utils/paymentScheduleValue";
import WarningAlert from "../Alert/WarningAlert";
export default function PaymentScheduleMobile({ propDetails }) {
  // token section
  const accessToken = localStorage.getItem("accestoken");
  const location = useLocation();
  const { id } = useParams();
  const userId = localStorage.getItem("user_id");
  const { property_info, invoice_info } = propDetails;
  const [tokenCount, setTokenCount] = useState(1);
  const [formattedPrice, setFormattedPrice] = useState(0);
  const [platformFee, setPlatformFee] = useState(1);
  const [gstFee, setGstFee] = useState(1);
  const initialTotalFee =
    parseFloat(property_info?.price_per_share) + platformFee + gstFee;
  const [totalFee, setTotalFee] = useState(initialTotalFee);
  const [reachedTokenLimit, setReachedTokenLimit] = useState(false);
  const [isUserEmail, setUserEmail] = useState("");
  const [isEmailUpdated, setEmailupdated] = useState(true);
  const [isLoggedIn, setLoggedin] = useState(false);
  const [kycStatus, setKycStatus] = useState("");
  const [showStickyPriceSection, setshowStickyPriceSection] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const priceSectionRef = useRef(null);
  const navigate = useNavigate();

  let isPropComingSoon = property_info?.sale_status;

  const fetchUserDetails = () => {
    getUserDetails(
      userId,
      accessToken,
      setLoggedin,
      setKycStatus,
      setUserEmail,
      navigate
    );
  };

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, []);

  // useEffect(()=>{
  //   getPropertyDetails(propertyDetailsURl)
  // },[id])

  //token section

  //ectract the element with property invoice_details
  let invoiceDetails = invoice_info?.find((el) =>
    el?.hasOwnProperty("invoice_details")
  );

  //get the array
  invoiceDetails = invoiceDetails?.invoice_details;

  //get the element with platformm handling fee name as an object
  let phmArray = invoiceDetails?.find(
    (el) =>
      el.invoice_name.toLowerCase() === "Platform Handling fee".toLowerCase()
  );
  const phm_percentage = phmArray?.invoice_price;

  let gstArray = invoiceDetails?.find(
    (el) => el.invoice_name.toLowerCase() === "gst".toLowerCase()
  );
  const gst_percentage = gstArray?.invoice_price;

  const price = property_info?.price_per_share;
  const tokenLimit = property_info?.limit_per_share;

  const handleToggleStickyPriceSection = () => {
    setshowStickyPriceSection(!showStickyPriceSection);
  };

  const handleClickOutside = (event) => {
    ClickOutside(event, priceSectionRef, setshowStickyPriceSection);
  };

  const handleClosePushSection = () => {
    setshowStickyPriceSection(false);
  };

  const [totalAmount, settotalAmount] = useState(0);
  useEffect(() => {
    settotalAmount(
      propDetails?.payment_schedules?.[0]?.amount +
        propDetails?.payment_schedules?.[1]?.amount +
        propDetails?.payment_schedules?.[2]?.amount +
        propDetails?.payment_schedules?.[3]?.amount
    );
  }, [propDetails]);

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIncrement = () => {
    IncrementToken(tokenCount, setTokenCount, tokenLimit, setReachedTokenLimit);
  };

  const handleDecrement = () => {
    DecrementToken(tokenCount, setTokenCount, setReachedTokenLimit);
  };

  const PerTokenPrice = property_info?.price_per_share;

  useEffect(() => {
    const { totalPrice, calculatedPlatformFee, gst_fee, total_fee } =
      calculateTotalPrice(
        PerTokenPrice,
        tokenCount,
        phm_percentage,
        gst_percentage
      );

    setFormattedPrice(totalPrice.toLocaleString());
    setPlatformFee(calculatedPlatformFee);
    setGstFee(gst_fee);
    setTotalFee(total_fee);
  }, [tokenCount, PerTokenPrice, phm_percentage, gst_percentage]);

  // Calculate the total price
  const totalPrice = price * tokenCount;

  const propertyCity = property_info?.property_city;
  const propertyName = property_info?.property_name;

  const handleProceed = () => {
    ProceedToPurchase(
      isLoggedIn,
      kycStatus,
      isUserEmail,
      navigate,
      tokenCount,
      totalPrice,
      formattedPrice,
      property_info,
      gstFee,
      platformFee,
      totalFee,
      id,
      PerTokenPrice,
      setEmailupdated,
      location,
      propertyName,
      propertyCity
    );
  };

  const formatDate = (date) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}\.\d{3}Z)?$/;

    if (typeof date === "string" && dateRegex.test(date)) {
      const dateObj = new Date(date);
      const formattedDate = dateObj.toLocaleString("en-US", {
        month: "short",
        year: "2-digit"
      });
      return formattedDate.replace(" ", "'");
    }

    return date;
  };

  return (
    <>
      {!isEmailUpdated && <WarningAlert message="Please Update Your Email" />}
      <div class="sticky-button sm:hidden">
        <button
          disabled={property_info?.property_sale_type === "SOLD OUT"}
          onClick={handleToggleStickyPriceSection}
          className={`w-full ${
            property_info?.property_sale_type === "SOLD OUT"
              ? "bg-lightgray text-black"
              : "bg-secondary text-white"
          }  py-2.5 px-4 rounded-md font-semibold text-base`}
        >
          {property_info?.property_sale_type === "SOLD OUT"
            ? "Sold Out"
            : "Select Token"}
        </button>
      </div>

      {showStickyPriceSection && (
        <StickyPriceComponent
          formattedPrice={formattedPrice}
          gstFee={gstFee}
          handleClosePushSection={handleClosePushSection}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          handleProceed={handleProceed}
          platformFee={platformFee}
          price={price}
          priceSectionRef={priceSectionRef}
          reachedTokenLimit={reachedTokenLimit}
          showStickyPriceSection={showStickyPriceSection}
          tokenCount={tokenCount}
          tokenLimit={tokenLimit}
          totalFee={totalFee}
          isPropComingSoon={isPropComingSoon}
        />
      )}

      <div>
        <div className="tab-shadow bg-white font-nunito flex flex-col gap-4 rounded-lg p-2.5">
          <p className="text-black text-base font-bold text-left">
            {propDetails?.property_info?.property_name?.split(",").pop().trim()}{" "}
            {propDetails?.property_info?.property_city}
            <br />
            <span className="text-sm">
              {propDetails?.property_info?.configuration},{" "}
              {propDetails?.property_info?.property_name.split(" ")[0]}-
              {propDetails?.property_info?.block_no},{" "}
              {"UNIT #" + propDetails?.property_info?.unit_no}
            </span>
          </p>

          {/* sec-2 */}
          <div className="flex flex-col gap-2 p-2.5">
            <div className="flex">
              <div className="pTitle">SNo.</div>
              <div className="pDetails">1</div>
            </div>

            <div className="flex">
              <div className="pTitle">Description</div>
              <div className="pDetails">
                <p>
                  {propDetails?.payment_schedules?.[0]?.schedule_order}st
                  payment schedule <br></br>
                  {propDetails?.payment_schedules?.[0]?.description}
                </p>
              </div>
            </div>

            <div className="flex">
              <div className="pTitle">Amount (IN ₹)</div>
              <div className="pDetails">
                ₹{" "}
                {propDetails?.payment_schedules?.[0]?.amount?.toLocaleString(
                  "en-IN"
                )}
              </div>
            </div>

            <div className="flex items-center">
              <div className="pTitle">Collection Dates</div>
              <div className="pDetails">
                <div className="flex flex-col">
                  <p>
                    {formatDate(propDetails?.payment_schedules?.[0]?.due_date)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr className="paymetSchHr" />

          {/* sec-3 */}
          <div className="flex flex-col gap-2 p-2.5">
            <div className="flex">
              <div className="pTitle">SNo.</div>
              <div className="pDetails">2</div>
            </div>

            <div className="flex">
              <div className="pTitle">Description</div>
              <div className="pDetails">
                <p>
                  {propDetails?.payment_schedules?.[1]?.schedule_order}nd
                  payment schedule <br></br>
                  {propDetails?.payment_schedules?.[1]?.description}
                </p>
              </div>
            </div>

            <div className="flex">
              <div className="pTitle">Price</div>
              <div className="pDetails">
                ₹{" "}
                {propDetails?.payment_schedules?.[1]?.amount?.toLocaleString(
                  "en-IN"
                )}
              </div>
            </div>

            <div className="flex items-center">
              <div className="pTitle">Collection Dates</div>
              <div className="pDetails">
                <p>
                  By {formatDate(propDetails?.payment_schedules?.[1]?.due_date)}
                </p>
              </div>
            </div>
          </div>

          <hr className="paymetSchHr" />

          {/* sec-4 */}
          <div className="flex flex-col gap-2 p-2.5">
            <div className="flex">
              <div className="pTitle">SNo.</div>
              <div className="pDetails">3</div>
            </div>

            <div className="flex">
              <div className="pTitle">Description</div>
              <div className="pDetails">
                <p>
                  {propDetails?.payment_schedules?.[2]?.schedule_order}rd
                  payment schedule <br></br>
                  {propDetails?.payment_schedules?.[2]?.description}
                </p>
              </div>
            </div>

            <div className="flex">
              <div className="pTitle">Price</div>
              <div className="pDetails">
                ₹{" "}
                {propDetails?.payment_schedules?.[2]?.amount?.toLocaleString(
                  "en-IN"
                )}
              </div>
            </div>

            <div className="flex items-center">
              <div className="pTitle">Collection Dates</div>
              <div className="pDetails">
                <p>
                  By {formatDate(propDetails?.payment_schedules?.[2]?.due_date)}
                </p>
              </div>
            </div>
          </div>

          <hr className="paymetSchHr" />

          {/* sec-5 */}
          <div className="flex flex-col gap-2 p-2.5">
            <div className="flex">
              <div className="pTitle">SNo.</div>
              <div className="pDetails">4</div>
            </div>

            <div className="flex">
              <div className="pTitle">Description</div>
              <div className="pDetails">
                <p>
                  {propDetails?.payment_schedules?.[3]?.schedule_order}th
                  payment schedule <br></br>
                  {propDetails?.payment_schedules?.[3]?.description}
                </p>
              </div>
            </div>

            <div className="flex">
              <div className="pTitle">Price</div>
              <div className="pDetails">
                ₹{" "}
                {propDetails?.payment_schedules?.[3]?.amount?.toLocaleString(
                  "en-IN"
                )}
              </div>
            </div>

            <div className="flex items-center">
              <div className="pTitle">Collection Dates</div>
              <div className="pDetails">
                <p>
                  By {formatDate(propDetails?.payment_schedules?.[3]?.due_date)}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex  flex-col gap-2 py-4">
          <div className="flex">
            <div className="finalTitleM">
              <p>Final Unit Token</p>
              <p> Price (Approximate)</p>
            </div>
            <div className="finalPriceM">
              ₹ {totalAmount?.toLocaleString("en-IN")}
            </div>
          </div>

          <div className="flex">
            <div className="noteTitleM text-blue">
              NOTE: {propDetails?.property_info?.payment_schedule_note}
            </div>
          </div>
        </div>

        {/* general conditions */}
        <div className="generalConditionM">
          <p className="text-gray text-xs font-semibold text-left">
            Please Note General Conditions :
          </p>

          <div className="conditionsSecM">
            <p>
              1 {propDetails?.property_info?.payment_schedule_conditions?.[0]}
            </p>
            <p>
              2 {propDetails?.property_info?.payment_schedule_conditions?.[1]}
            </p>
            <p>
              3 {propDetails?.property_info?.payment_schedule_conditions?.[2]}
            </p>
            <p>
              4 {propDetails?.property_info?.payment_schedule_conditions?.[3]}
            </p>
            <p>
              5 {propDetails?.property_info?.payment_schedule_conditions?.[4]}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
