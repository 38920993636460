import { ALML_API_URL, ApiBaseUrl } from "../Utils/urls";

export const getPredictedData = (
  predictPayload,
  selectedPriceRange,
  setPredictResult,
  setLoading,
  mainCity
) => {
  setLoading(true);

  fetch(`${ALML_API_URL}/predict`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      predictions: predictPayload,
      priceType: selectedPriceRange,
      city: mainCity
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((resp) => {
      setLoading(false);
      setPredictResult(resp);

      // console.log(data);
    })
    .catch((error) => {
      setLoading(false);
      console.error("There was a problem with the request:", error);
      // Handle error
    });
};

export const fetchALMLLocations = (
  setAreaLoading,
  accessToken,
  setProjectionLocation
) => {
  setAreaLoading(true);
  fetch(`${ApiBaseUrl}/fr-property/get_ml_available_locations`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken
    }
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      setAreaLoading(false);
      // console.log(res);
      setProjectionLocation(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const TrackLocationAvailability = (accessToken, searchInput) => {
  fetch(`${ApiBaseUrl}/fr-property/check_location_availability`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken
    },
    body: JSON.stringify({
      location_name: searchInput
    })
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
