import React from "react";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useBookedPropertyFilters } from "../../Utils/bookedPropFilter";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { ApiBaseUrl } from "../../Utils/urls";
import { initialPropertyFiltersBooked } from "../../Utils/bookedPropFilter";
import { projectionLocations } from "../../Utils/projectionLocation";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const CityButton = ({ city, selectedCities, onClick, isDisabled }) => {
  const isSelected = selectedCities.includes(city);

  return (
    <button
      onClick={() => !isDisabled && onClick(city)} // Only call onClick if not disabled
      disabled={isDisabled}
      className={`cityButton text-black text-sm h-8 px-2 ${
        isSelected ? "bg-blue text-white" : ""
      } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
    >
      {city}
    </button>
  );
};

const years = [2024, 2025, 2026, 2027, 2028, 2029, 2030];
const YearButton = ({ year, selectedYear, onClick }) => {
  const isSelected = selectedYear === year;

  return (
    <button
      onClick={onClick}
      className={`cityButton text-black text-sm h-8 px-2 ${
        isSelected ? "bg-blue text-white" : ""
      } `}
    >
      {year}
    </button>
  );
};

const quarters = [
  { value: "Jan-Mar", label: "January-March" },
  { value: "Apr-Jun", label: "April-June" },
  { value: "Jul-Sep", label: "July-September" },
  { value: "Oct-Dec", label: "October-December" }
];

const QuarterButton = ({ quarter, selectedQuarter, onClick }) => {
  const isSelected = selectedQuarter === quarter.value;

  return (
    <button
      onClick={onClick}
      className={`cityButton text-black text-sm h-8 px-2 ${
        isSelected ? "bg-blue text-white" : ""
      } `}
    >
      {quarter.label}
    </button>
  );
};

function MapFilterMobile(props) {
  const {
    searchInput,
    filteredAreas,
    handleSearchChange,
    setIsOpen,
    isOpen,
    dropdownRef
  } = props;
  console.log(props);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isClearButtonActive, setIsClearButtonActive] = useState(false);

  const [isOpenOptionProp, setIsOpenOptionsProp] = useState(false);
  const [isOpenOptionsSales, setIsOpenOptionsSales] = useState(false);
  const [isOpenOptionsQuarter, setIsOpenOptionsQuarter] = useState(false);
  const [isOpenOptionsYear, setIsOpenOptionsYear] = useState(false);
  const [isOpenOptionsCity, setIsOpenOptionsCity] = useState(false);

  const [isOpenOptionsPrice, setIsOpenOptionsPrice] = useState(false);

  const toggleOptionsProp = () => {
    setIsOpenOptionsProp((prevState) => !prevState);
  };

  const toggleOptionsSales = () => {
    setIsOpenOptionsSales((prevState) => !prevState);
  };
  const toggleOptionsYear = () => {
    setIsOpenOptionsYear((prevState) => !prevState);
  };
  const toggleOptionsQuarter = () => {
    setIsOpenOptionsQuarter((prevState) => !prevState);
  };
  const toggleOptionsCity = () => {
    setIsOpenOptionsCity((prevState) => !prevState);
  };

  const toggleOptionsPriceRange = () => {
    setIsOpenOptionsPrice((prevState) => !prevState);
  };

  const {
    selectedYear,
    setSelectedYear,
    handleLocationSelect,
    selectedQuarter,
    setSelectedQuarter,
    setSelectedCities,
    selectedPriceRange,
    handlePriceRangeChange,
    selectedCities,
    mainCitiList
  } = props;

  const [isLoggedin, setisLoggedin] = useState(false);
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem("user_id");
    const sessionLSFromLocalStorage = localStorage.getItem("sessionLS");

    if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
      setisLoggedin(true);
      setUserId(userIdFromLocalStorage);
    }
  }, []);

  const [cityClicked, setcityClicked] = useState(false);
  const [BuilderClicked, setBuilderClicked] = useState(false);

  const { BookedpropertyFilters, setBookedPropertyFilters } =
    useBookedPropertyFilters();

  const [clearButtonBeingClicked, setclearButtonBeingClicked] = useState(false);

  const handleClearClick = () => {
    props.setshowMobFilter(!props.showMobFilter);
    setclearButtonBeingClicked(true);
    setTimeout(() => {
      setclearButtonBeingClicked(false);
    }, 200);

    setSelectedCities(["JP Nagar"]);
  };

  return (
    <>
      <div
        className="hideMobFilter flex flex-col  bg-white "
        style={{ height: "100%" }}
      >
        <div className="h-[400px] overflow-y-auto z-[9999]">
          <div className=" text-black pt-4 flex flex-col py-2 px-4">
            <div className="flex flex-row justify-between py-2 mt-10">
              <div
                className="mt-2  text-[16px] font-bold"
                onClick={() => props.setshowMobFilter(false)}
              >
                Filter
              </div>
              <div
                className={`mt-2  text-[14px] font-normal cursor-pointer px-2 py-1 rounded-md ${
                  clearButtonBeingClicked ? "clickedClearDashFilter" : ""
                }`}
                onClick={handleClearClick}
              >
                Clear All
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-center">
            <hr style={{ color: "rgba(217, 217, 217, 1)", width: "100%" }}></hr>
          </div>

          <div
            className=" text-black flex flex-col py-2 px-4 "
            onClick={() => setcityClicked(true)}
          >
            <div
              className="flex flex-row justify-between"
              onClick={toggleOptionsCity}
            >
              <div className="text-base font-medium text-start">
                Select City
              </div>

              <div className="flex flex-col justify-center">
                {isOpenOptionsCity ? <FaCaretRight /> : <FaCaretDown />}
              </div>
            </div>

            {isOpenOptionsCity && (
              <div className=" flex flex-col gap-2 p-2 -ml-2 h-64 overflow-y-auto">
                <select
                  name=""
                  value={props.mainCity}
                  className="px-[2px] py-[4px] rounded-md"
                  style={{ border: "1px solid #CCCCCC" }}
                  onChange={(e) => props.setMainCity(e.target.value)}
                >
                  <option value="" disabled>
                    Select City
                  </option>

                  {mainCitiList.length > 0 &&
                    mainCitiList.map((city) => (
                      <option value={city}>{city}</option>
                    ))}
                </select>

                <div className="relative w-full">
                  <div
                    className="flex items-center justify-between gap-2 px-[2px] py-[4px] rounded-md cursor-pointer"
                    style={{ border: "1px solid #CCCCCC" }}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <div className="">
                      {selectedCities.length > 25
                        ? selectedCities.substring(24, 0) + "..."
                        : selectedCities || "Select a city"}{" "}
                    </div>
                    <MdOutlineKeyboardArrowDown
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    />
                  </div>

                  {isOpen && (
                    <div
                      style={{ border: "1px solid #CCCCCC" }}
                      className="absolute w-full bg-white border border-t-0 z-[9999] rounded-md max-h-60 overflow-y-auto"
                      ref={dropdownRef}
                    >
                      {/* Sticky search input */}
                      <div className="px-3 py-2">
                        <input
                          type="text"
                          className="w-full px-2 py-1 border border-gray-300 rounded-md sticky top-0 bg-white z-10"
                          placeholder="Search areas..."
                          value={searchInput}
                          onChange={handleSearchChange}
                        />
                      </div>

                      {/* Filtered options */}
                      <div className="flex flex-col gap-1 items-start justify-start max-h-[calc(100vh-120px)] overflow-y-auto">
                        {filteredAreas.map((city, index) => (
                          <div
                            key={index}
                            className="px-3 py-2 cursor-pointer hover:bg-gray-100 text-left"
                            onClick={() => handleLocationSelect(city)}
                          >
                            {city}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="relative w-64">
            <div
              className="flex items-center justify-between gap-2 px-[2px] py-[4px] rounded-md cursor-pointer"
              style={{ border: "1px solid #CCCCCC" }}
              onClick={() => setIsOpen(!isOpen)}
            >
              <div className="">
                {selectedCities.length > 25
                  ? selectedCities.substring(24, 0) + "..."
                  : selectedCities || "Select a city"}{" "}
              </div>
              <MdOutlineKeyboardArrowDown
                style={{ fontWeight: "bold", fontSize: "20px" }}
              />
            </div>

            {isOpen && (
              <div
                style={{ border: "1px solid #CCCCCC" }}
                className="absolute w-full bg-white border border-t-0 z-[9999] rounded-md max-h-60 overflow-y-auto"
                ref={dropdownRef}
              >
                {/* Sticky search input */}
                <div className="px-3 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md sticky top-0 bg-white z-10"
                    placeholder="Search areas..."
                    value={searchInput}
                    onChange={handleSearchChange}
                  />
                </div>

                {/* Filtered options */}
                <div className="flex flex-col gap-1 items-start justify-start max-h-[calc(100vh-120px)] overflow-y-auto">
                  {filteredAreas.map((city, index) => (
                    <div
                      key={index}
                      className="px-3 py-2 cursor-pointer hover:bg-gray-100 text-left"
                      onClick={() => handleLocationSelect(city)}
                    >
                      {city}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-row justify-center">
            <hr style={{ color: "rgba(217, 217, 217, 1)", width: "100%" }}></hr>
          </div>
          <div className=" text-black flex flex-col px-4 py-2">
            <div
              className="flex flex-row justify-between"
              onClick={toggleOptionsYear}
            >
              <div className="text-base font-medium text-start">
                Select Year
              </div>

              <div className="flex flex-col justify-center">
                {isOpenOptionsYear ? <FaCaretRight /> : <FaCaretDown />}
              </div>
            </div>

            {isOpenOptionsYear && (
              <div className=" flex flex-row flex-wrap gap-2 p-2 -ml-2">
                {years.map((year) => (
                  <YearButton
                    key={year}
                    year={year}
                    selectedYear={selectedYear}
                    onClick={() => setSelectedYear(year)}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-row justify-center">
            <hr style={{ color: "rgba(217, 217, 217, 1)", width: "100%" }}></hr>
          </div>

          <div className=" text-black flex flex-col px-4 py-2">
            <div
              className="flex flex-row justify-between"
              onClick={toggleOptionsQuarter}
            >
              <div className="text-base font-medium text-start">
                Select Quarter
              </div>

              <div className="flex flex-col justify-center">
                {isOpenOptionsQuarter ? <FaCaretRight /> : <FaCaretDown />}
              </div>
            </div>

            {isOpenOptionsQuarter && (
              <div className=" flex flex-row flex-wrap gap-2 p-2 -ml-2">
                {quarters.map((quarter) => (
                  <QuarterButton
                    key={quarter.value}
                    quarter={quarter}
                    selectedQuarter={selectedQuarter}
                    onClick={() => setSelectedQuarter(quarter.value)}
                  />
                ))}
              </div>
            )}
          </div>

          <div className="flex flex-row justify-center">
            <hr style={{ color: "rgba(217, 217, 217, 1)", width: "100%" }}></hr>
          </div>
          {selectedCities && selectedCities.length > 1 && (
            <div className=" text-black flex flex-col px-4 py-2">
              <div
                className="flex flex-row justify-between"
                onClick={toggleOptionsPriceRange}
              >
                <div className="text-base font-medium text-start">
                  Price Range
                </div>

                <div className="flex flex-col justify-center">
                  {isOpenOptionsPrice ? <FaCaretRight /> : <FaCaretDown />}
                </div>
              </div>

              {isOpenOptionsPrice && (
                <div className=" flex flex-row flex-wrap gap-2 p-2 -ml-2">
                  <div className=" flex flex-row gap-2 ">
                    <input
                      type="radio"
                      name="price"
                      id="minPrice"
                      value="min"
                      checked={selectedPriceRange === "min"}
                      onChange={handlePriceRangeChange}
                    />
                    <label
                      htmlFor="minPrice"
                      className={`flex flex-col justify-center ${
                        selectedPriceRange === "min" ? "selected" : ""
                      }`}
                    >
                      Minimum Price
                    </label>
                  </div>

                  <div className=" flex flex-row gap-2">
                    <input
                      type="radio"
                      name="price"
                      id="avgPrice"
                      value="avg"
                      checked={selectedPriceRange === "avg"}
                      onChange={handlePriceRangeChange}
                    />
                    <label
                      htmlFor="avgPrice"
                      className={`flex flex-col justify-center ${
                        selectedPriceRange === "avg" ? "selected" : ""
                      }`}
                    >
                      Average Price
                    </label>
                  </div>

                  <div className=" flex flex-row gap-2">
                    <input
                      type="radio"
                      name="price"
                      id="maxPrice"
                      value="max"
                      checked={selectedPriceRange === "max"}
                      onChange={handlePriceRangeChange}
                    />
                    <label
                      htmlFor="maxPrice"
                      className={`flex flex-col justify-center ${
                        selectedPriceRange === "max" ? "selected" : ""
                      }`}
                    >
                      Maximum Price
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row w-full justify-between absolute bottom-0 py-2 px-4 mt-10 bg-white">
          <div
            className="flex flex-col justify-center mt-2   text-[14px] font-normal belowBorders h-[44px] w-[150px] rounded-lg cursor-pointer"
            onClick={() => props.setshowMobFilter(false)}
          >
            Close
          </div>
          <div
            className="flex flex-col justify-center mt-2  text-[14px] text-white font-normal belowBorders h-[44px] w-[150px] rounded-lg bg-secondary cursor-pointer"
            onClick={() => props.setshowMobFilter(false)}
          >
            Apply
          </div>
        </div>
      </div>
    </>
  );
}

export default MapFilterMobile;
