import { ALML_API_URL } from "../Utils/urls";

export const fetchPrdictedRoi = (
  investmentAmount,
  selectedRiskCapacity,
  timePeriod,
  setEstRoi,
  setTotalReturn,
  setLat,
  setLong,
  setLocality,
  selectedCity
  //setMinValErr
) => {
  let InvestmentValue;
  if (investmentAmount < 5000) {
    InvestmentValue = 5000;
  } else {
    InvestmentValue = investmentAmount;
  }

  console.log(InvestmentValue);
  fetch(`${ALML_API_URL}/predict_roi`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      city: selectedCity,
      amount_to_invest: Number(InvestmentValue),
      risk_capacity: selectedRiskCapacity,
      time_period: Number(timePeriod)
    })
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      // if (res.error === "Investment amount must be greater than 0.") {
      //   setMinValErr(true);
      // } else {
      //   setMinValErr(false);
      // }

      setEstRoi(res.best_locality.roi);
      setTotalReturn(res.total_amount);
      setLat(res.best_locality.latitude);
      setLong(res.best_locality.longitude);
      setLocality(res.best_locality.locality);
    })
    .catch((err) => {});
};
