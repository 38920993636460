import React from "react";
import InvestmentRiskInput from "./InvestmentRiskInput";

export default function MobileInputSection({
  inputSectionRef,
  showStickyInput,
  setShowStickyInput,
  investmentAmount,
  setinvestmentAmount,
  setMinValErr,
  isMinValErr,
  handleRiskCapacityChange,
  selectedRiskCapacity,
  timePeriod,
  setTimePeriod,
  selectedCity,
  handlecityChange
}) {
  return (
    <div
      ref={inputSectionRef}
      class={`sticky-input-section ${showStickyInput ? "show" : ""} sm:hidden`}
    >
      <div className="flex flex-col gap-2">
        {/* close-section */}
        <div
          className=" flex flex-row justify-center cursor-pointer"
          onClick={() => setShowStickyInput(false)}
        >
          <div
            className="h-1  w-60 mb-1 rounded-lg"
            style={{ backgroundColor: "#D9D9D9" }}
          ></div>
        </div>
        {/* close-section */}

        <div className="flex justify-between items-center border-b border-b-lightgray pb-2">
          <p className="font-bold text-xs">Investment Inputs</p>

          <select
            value={selectedCity}
            className="border border-lightgray rounded-md px-3"
            onChange={handlecityChange}
          >
            <option value="Bangalore">Bangalore</option>
          </select>
        </div>

        <InvestmentRiskInput
          investmentAmount={investmentAmount}
          setinvestmentAmount={setinvestmentAmount}
          setMinValErr={setMinValErr}
          isMinValErr={isMinValErr}
          handleRiskCapacityChange={handleRiskCapacityChange}
          selectedRiskCapacity={selectedRiskCapacity}
          timePeriod={timePeriod}
          setTimePeriod={setTimePeriod}
        />
        <div className="flex justify-center pt-2">
          <button
            className="border border-lightgray text-gray  px-4 py-2.5 rounded-lg"
            onClick={() => setShowStickyInput(false)}
          >
            See Investment Returns
          </button>
        </div>
      </div>
    </div>
  );
}
