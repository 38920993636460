import logo from "./logo.svg";
import "./App.css";
import PropertyPage from "./Components/PropertyPage/Index";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { useEffect, useState } from "react";
import PropertyDetailsPage from "./Components/PropertyDetails/PropertyDetailsPage";
import ResalePropertyDetailsPage from "./Components/ResaleProperty/ResalePropertyDetailsPage";
import SignupLogin from "./Components/Signup/SignupLogin";

import EnterOtp from "./Components/Signup/EnterOtp";
import VerifyEmail from "./Components/Signup/VerifyMobile";
// import EmailVerifyOtp from './Components/Signup/EmailVerifyOtp';

import VerificationDone from "./Components/Signup/VerificationDone";
import MemoizedEmailVerifyOtp from "./Components/Signup/EmailVerifyOtp";
import EditNumber from "./Components/Signup/EditNumber";
import Dashboard from "./Components/Dashboard/Index";
import PurchasePage from "./Components/Purchase/PurchasePage";
import PurchasePageResale from "./Components/PurchaseResale/PurchasePage";

import Myprofile from "./Components/Myprofile/Index";
import Wishlist from "./Components/Wishlist/Index";
import Overview from "./Components/Overview/Index";
import Analytics from "./Components/Analytics/Index";
import Transactions from "./Components/Transactions/Index";
import Mymanager from "./Components/Mymanager/Index";
import Settings from "./Components/Settings/Index";
import KycPage from "./Components/Kyc/KycPage";
import KycVerifiedComp from "./Components/Kyc/KycVerifiedComp";
import NomineePage from "./Components/Nominees/NomineePage";
import NomineeDetailsForm from "./Components/Nominees/NomineeDetailsForm";
import EditNominee from "./Components/Nominees/EditNominee";
import BankDetailsPage from "./Components/BankDetails/BankDetailsPage";
import BankAddForm from "./Components/BankDetails/BankAddForm";
import PrivateRoute from "./Components/Routes/PrivateRoute";

import KycRejectedCompo from "./Components/Kyc/KycRejectedCompo";
import EditBankDetails from "./Components/BankDetails/EditBankDetails";
import Projection from "./Components/Analytics/Projection";

import VerifyMobile from "./Components/Signup/VerifyMobile";
import VerifyMobileOtp from "./Components/Signup/VerifyMobileOtp";
import CookieLogout from "./Components/Signup/CookieLogout";

import RestrictPurchaseRoute from "./Components/Routes/RestrictPurchaseRoute";
import RestrictAuthenticationRoute from "./Components/Routes/RestrictAuthenticationRoute";
import SmartAdvisorContainer from "./Components/SmartAdvisor/SmartAdvisorContainer";
import Map from "./Components/PriceprojectionMap/Map";
import Map2 from "./Components/PriceprojectionMap/Map2";
import ProjectionMap from "./Components/PriceprojectionMap/ProjectionMap";

function App() {
  useEffect(() => {
    // Change the title here
    document.title = "PropFTX";
  }, []);

  return (
    <div className="App">
      <Router>
        <CookieLogout />

        {/* component for restrincting direct access to puchase route */}

        <RestrictPurchaseRoute />

        <Routes>
          <Route exact path="/" element={<PropertyPage />} />

          {/* <Route exact path="/details/:id" element={<PropertyDetailsPage/>} /> */}
          <Route
            exact
            path="/:city/:prop-name/:id"
            element={<PropertyDetailsPage />}
          />
          <Route
            exact
            path="/:city/:prop-name/:sale-type/:id"
            element={<ResalePropertyDetailsPage />}
          />
          <Route exact path="/login" element={<SignupLogin />} />

          <Route exact path="/otp" element={<EnterOtp />} />
          <Route exact path="/verify-mobile" element={<VerifyMobile />} />
          <Route
            exact
            path="/verify-mobile-otp"
            element={<VerifyMobileOtp />}
          />
          <Route
            exact
            path="/verify-with-otp"
            element={<MemoizedEmailVerifyOtp />}
          />
          <Route
            exact
            path="/verification-done"
            element={<VerificationDone />}
          />

          <Route exact path="/edit-number" element={<EditNumber />} />

          <Route
            exact
            path="/purchase/resale"
            element={
              <PrivateRoute>
                <PurchasePageResale />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/purchase"
            element={
              <PrivateRoute>
                <PurchasePage />
              </PrivateRoute>
            }
          />
          {/* <Route exact path="/success" element={ <PurchaseSuccess /> } /> */}
          {/* <Route exact path="/failure" element={ <PurchaseFailure /> } /> */}

          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/user/profile"
            element={
              <PrivateRoute>
                <Myprofile />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/user/wishlist"
            element={
              <PrivateRoute>
                <Wishlist />
              </PrivateRoute>
            }
          />
          <Route exact path="/user/overview" element={<Overview />} />
          <Route exact path="/user/projections" element={<Projection />} />
          <Route
            exact
            path="/user/transactions"
            element={
              <PrivateRoute>
                <Transactions />
              </PrivateRoute>
            }
          />
          <Route exact path="/user/mymanager" element={<Mymanager />} />
          <Route exact path="/user/settings" element={<Settings />} />

          <Route
            exact
            path="/user/kyc"
            element={
              <PrivateRoute>
                <KycPage />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/user/kyc/verified"
            element={
              <PrivateRoute>
                <KycVerifiedComp />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/user/kyc/rejected"
            element={
              <PrivateRoute>
                <KycRejectedCompo />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/user/nominee"
            element={
              <PrivateRoute>
                <NomineePage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/user/nominee/add"
            element={
              <PrivateRoute>
                <NomineeDetailsForm />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/user/nominee/:id"
            element={
              <PrivateRoute>
                <EditNominee />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/user/bank-details"
            element={
              <PrivateRoute>
                <BankDetailsPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/user/bank-details/add"
            element={
              <PrivateRoute>
                <BankAddForm />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/user/bank-details/edit"
            element={
              <PrivateRoute>
                <EditBankDetails />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/user/projection"
            element={
              <PrivateRoute>
                <Projection />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/user/advisor"
            element={
              <PrivateRoute>
                <SmartAdvisorContainer />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/user/ai/map"
            element={
              <PrivateRoute>
                <ProjectionMap />
              </PrivateRoute>
            }
          />

          <Route exact path="/map" element={<Map />}></Route>
          <Route exact path="/map2" element={<Map2 />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
