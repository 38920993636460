import React, { useEffect, useRef, useState } from "react";
import "./profile-style.css";
import { MdOutlineCameraAlt } from "react-icons/md";
import userIcon from "../../Assets/icons/user-icon.png";
import loader from "../../Assets/icons/Loader.gif";

import { AiOutlineEdit } from "react-icons/ai";
import CustomeProfileInput from "./CustomeProfileInput";
import axios from "axios";
import { ApiBaseUrl, PropertImageBaseUrl } from "../../Utils/urls";
import {
  EmailUpdateOnly,
  OTPVerifyForEmailUpdate,
  SaveChangeProfile,
  fetchUserAmlDetailes,
  fetchUserDetailes
} from "../../Api/ProfileApi";
import { changeDateFormat } from "../../Utils/DateFormat";
import SuccessAlert from "../Alert/SuccessAlert";
import WarningAlert from "../Alert/WarningAlert";
import ProfileModal from "./ProfileModal";
import { OtpTab } from "../Signup/OtpTab";
import ProfileAccordianTab from "./ProfileAccordianTab";
import { IoMdInformationCircleOutline } from "react-icons/io";

import ToggleSwitch from "./ToggleSwitch";
import ErrorAlert from "../Alert/ErrorAlert";
import { isDate18YearsFromNow } from "../../Utils/DOBValidation";
import InfoPopup from "../InfoPopup/InfoPopup";
import { indianStatesCode } from "../../Utils/isoStateCode";
import { getcountryCode } from "../../Utils/CountryCode";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";
import { handlelogout } from "../../Utils/logout";

export default function ProfilePage() {
  const [isMobileEditable, setMobileIsEditable] = useState(false);
  const [isEmailEditable, setEmailEditable] = useState(false);
  // const [userProfileDetails,setUserProfile]=useState({})
  const [userProfileDetails, setUserProfile] = useState({ dob: null });
  const userId = localStorage.getItem("user_id");
  const accessToken = localStorage.getItem("accestoken");
  const [isUpdated, setIupdated] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState(userProfileDetails?.email);
  const [otp, setOtp] = useState("");
  const [allInputsFilled, setAllInputsFilled] = useState(false);
  const [isVerifying, setIsVerying] = useState(false);
  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [isOtpSentForEmail, setOtpSentEmail] = useState(false);
  const [isEmailUpdated, setEmailUpdated] = useState(false);
  const [UserAmlDetails, setUserAmlDetails] = useState({});
  const [isChecked, setIsChecked] = useState(false);

  const [isAmlupdated, setAmlUpdated] = useState(false);
  const [isamlvalueChanged, setAmlvaluChanged] = useState(false); //for aml details button enable/disable
  const [isuserDetailsChanged, setUserDetailsChnged] = useState(false); //for user details button enable/disable
  const [isOtpverifyError, setOtpVerifyError] = useState(false); //otp verofy error state

  const [selectedProfilePic, setSelectedProfilePic] = useState({});
  const [fileError, setFileError] = useState("");
  const [proflePic, setProfilePic] = useState("");
  const [previousProfilePic, setPreviousProfilePic] = useState("");
  const [profilePicBucket, setPicBucket] = useState("");

  const [isLoadingProfile, setLoadingProfile] = useState(false); //loader state for profile details change
  const [isLoadingAml, setLoadingAml] = useState(false); //loader state for aml details change

  const [dobFieldChanged, setDobFieldChanged] = useState(true);
  const [isDate18, setDate18] = useState(true);
  const inputRef = useRef(); //emailfield
  const [kycStatus, setKycStatus] = useState("");

  const [isFieldsFilled, setFieldsFilled] = useState(false);
  const [showNameUpdateAlert, setShowNameUpdate] = useState(false);
  const [showAlreadyEmailExist, setShowAlreadyEmailExist] = useState(false);

  //mobile number update
  const [selected, setSelected] = useState("IN"); //country code
  const [mobileNo, setMobileNo] = useState(userProfileDetails?.mobile);
  const [isMobileModalOpen, setMobileModalOpen] = useState(false);
  const [otpMobile, setOtpMobile] = useState("");
  const [allMobileInputsFilled, setAllMobileInputsFilled] = useState(false);
  const [mobiletimerActive, setMobileTimerActive] = useState(false);
  const [mtimer, setMtimer] = useState(60);
  const [isSendiingMobileOtp, setSendingMobileOtp] = useState(false); //loader while sending mobike update OTP
  const [isMobileOtpsent, setMobileOtpSent] = useState(false); //successfully otp sent
  const [isErrorMobileOtpsent, setErrorMobileOtpSent] = useState(false); //error in sending mobile otp
  const [alertMsgForMobileNo, setAlertMsgForMobile] = useState("");
  const [isMobileVerifyLoading, setaMobileVerifyLoading] = useState(false);
  const [isMobileOtpVerified, setMobileOtpVerified] = useState(false);
  const [alertMsgMobVerify, setAlertMsgMobVerify] = useState(""); //alert msg for mobile no OTP verification
  const [isMobileOtpVerifyErr, setMobileOtpVerifyErr] = useState(false);

  const [sessionForMobileNo, setSessionForMobile] = useState("");
  const [isProfileLoading, setProfileLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if all required fields are filled
    const isNameFilled = userProfileDetails && userProfileDetails.first_name;
    const isGenderFilled = userProfileDetails && userProfileDetails.gender;
    const isDobFilled =
      userProfileDetails &&
      userProfileDetails.dob &&
      userProfileDetails.dob !== "";
    const isAddressFilled = userProfileDetails && userProfileDetails.address;
    const isStateFiled =
      userProfileDetails && userProfileDetails.state_short_code;
    // Update the state variable based on field completion
    setFieldsFilled(
      isNameFilled &&
        isGenderFilled &&
        isDobFilled &&
        isAddressFilled &&
        isStateFiled
    );
  }, [userProfileDetails, updatedEmail]);

  //let customLabels=getcountryCode({})

  const customLabels = {
    IN: { primary: "India", secondary: "+91" }
  };

  useEffect(() => {
    fetchUserDetailes(userId, accessToken, setProfileLoading)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setProfileLoading(false);
        if (!res.success && res.data === "INVALID TOKEN OR USER ID MISMATCH") {
          handlelogout(navigate); // Log out the user
        }
        setUserProfile(res.data.profile);
        setUpdatedEmail(res.data.profile?.email);
        setMobileNo(res.data.profile?.mobile); //mobile no
        setKycStatus(res.data.kyc?.kyc_status);
        setSelected(res.data.profile?.country_short_code);

        if (res.data.profile?.profile_pic?.Key) {
          setProfilePic(res.data.profile?.profile_pic?.Key);

          setPreviousProfilePic(res.data.profile?.profile_pic?.Key);
          setPicBucket(res.data.profile?.profile_pic?.Bucket);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getuserAmlDetails();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(interval);
          setTimerActive(false);
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timerActive]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the input is for profile picture, update the selectedProfilePic state
    if (name === "profile_pic") {
      const file = e.target.files[0];
      // const size = file.size;

      if (file) {
        const fileTypeParts = file.type.split("/");
        const fileExtension =
          fileTypeParts.length === 2 ? fileTypeParts[1] : "";

        const maxSize = 5 * 1024 * 1024;

        if (file.type !== "image/jpeg" && file.type !== "image/png") {
          setFileError("Only JPG or PNG formats are allowed");
        } else if (file.size > maxSize) {
          setFileError("File size exceeds 5 MB limit");
        } else {
          setFileError("");

          const reader = new FileReader();
          reader.onload = (event) => {
            const base64String = event.target.result;
            // console.log((base64String));

            setSelectedProfilePic({
              extension: fileExtension,
              file_type: file.type,
              base64: base64String
            });

            setUserDetailsChnged(true);
          };
          reader.readAsDataURL(file);
        }
      }
    } else {
      setUserProfile({ ...userProfileDetails, [name]: value });
      // If the field being changed is not 'dob', set isuserDetailsChanged to true
      if (name !== "dob" && dobFieldChanged) {
        setUserDetailsChnged(true);
      } else {
        // If the field being changed is 'dob', check if it meets the age requirement
        const selectedDob = new Date(value);
        const is18years = isDate18YearsFromNow(selectedDob);
        // If dob meets the age requirement, set isuserDetailsChanged to true
        // Otherwise, set it to false
        setUserProfile({ ...userProfileDetails, [name]: value });
        setDobFieldChanged(is18years);
        setUserDetailsChnged(is18years);
        setDate18(is18years);
      }
    }
  };

  useEffect(() => {
    if (otp.length === 4) {
      setAllInputsFilled(true);
    } else {
      setAllInputsFilled(false);
    }
  }, [otp]);

  useEffect(() => {
    if (otpMobile.length === 4) {
      setAllMobileInputsFilled(true);
    } else {
      setAllMobileInputsFilled(false);
    }
  }, [otpMobile]);

  const handleSaveChange = () => {
    if (!selectedProfilePic.base64 && previousProfilePic) {
      // If no new picture is selected, use the previous profile picture
      let profilePicdata = {
        Key: previousProfilePic,
        Bucket: profilePicBucket
      };
      SaveChangeProfile(
        userProfileDetails,
        userId,
        accessToken,
        setIupdated,
        profilePicdata,
        setLoadingProfile
      );
    } else {
      SaveChangeProfile(
        userProfileDetails,
        userId,
        accessToken,
        setIupdated,
        selectedProfilePic,
        setLoadingProfile
      );
    }
  };
  //console.log(selectedProfilePic,proflePic,selected,userProfileDetails)

  const openModal = () => {
    setIsModalOpen(true);
    setTimer(60);
    setTimerActive(true);
  };

  // Function to close the email otp modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleResend = () => {
    handleEmailUpdate();
    setTimer(60);
    setTimerActive(true);
  };
  //send otp to new email
  const handleEmailUpdate = () => {
    if (!userProfileDetails.first_name) {
      setShowNameUpdate(true);
      setEmailEditable(false);
      if (!updatedEmail) {
        setUpdatedEmail("");
      } else {
        // If the user has entered an email, clear it
        setUpdatedEmail("");
      }

      setTimeout(() => {
        setShowNameUpdate(false);
      }, 3000);
      return;
    }
    EmailUpdateOnly(
      updatedEmail,
      userProfileDetails,
      userId,
      accessToken,
      setOtpSentEmail,
      openModal,
      setEmailEditable,
      setShowAlreadyEmailExist,
      setUpdatedEmail
    );
  };

  //otp verification
  const handleOnlyemailVerify = () => {
    OTPVerifyForEmailUpdate(
      userId,
      otp,
      userProfileDetails,
      accessToken,
      setEmailUpdated,
      closeModal,
      setOtpVerifyError,
      setEmailEditable
    );
  };

  //fetch user aml details
  const getuserAmlDetails = () => {
    fetchUserAmlDetailes(userId, accessToken)
      .then((res) => {
        const userAmlDetails = res.data.data[0];
        const isPep = userAmlDetails.is_pep === 1 ? true : false;
        userAmlDetails.is_pep = isPep;

        setUserAmlDetails(userAmlDetails);
        setIsChecked(isPep);
      })
      .catch((err) => {});
  };

  //aml onchange handler
  const handleAmlInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "is_pep") {
      const isCheckedValue = value === "true";
      setIsChecked(isCheckedValue);
    }

    // Update the state of UserAmlDetails as usual
    setUserAmlDetails({ ...UserAmlDetails, [name]: value });

    if (name === "source_of_income" && value !== "") {
      setAmlvaluChanged(true);
    }
  };

  //toggle switch onchange handler
  const handleToggleSwitchChange = (value) => {
    setIsChecked(value);
    handleAmlInputChange({
      target: { name: "is_pep", value: value.toString() }
    });
  };

  const amlBody = {
    ...UserAmlDetails,
    user_id: userId
  };

  //aml details update handler
  const handleAmlUpdate = () => {
    setLoadingAml(true);
    // console.log(amlBody);
    axios
      .post(`${ApiBaseUrl}/fr-user/add-aml-details`, amlBody, {
        headers: {
          Authorization: accessToken
        }
      })
      .then((res) => {
        setLoadingAml(false);
        // console.log(res.data);
        if (res.data.success) {
          setAmlUpdated(true);
          setTimeout(() => {
            setAmlUpdated(false);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isUpdated) {
      setUserDetailsChnged(false); // Disable the "Save Changes" button after profile is updated
    }
  }, [isUpdated]);

  useEffect(() => {
    if (isAmlupdated) {
      setAmlvaluChanged(false); // Disable the "Save Changes" button after aml is updated
    }
  }, [isAmlupdated]);

  const [showpoliticalPopup, setShowpoliticalfoPopup] = useState(false);
  const handleshowpoliticalPopup = () => {
    setShowpoliticalfoPopup(true);
  };

  const handleclosepoliticalPopup = () => {
    setShowpoliticalfoPopup(false);
  };

  //mobile no update
  const closeMobileModal = () => {
    setMobileModalOpen(false);
  };

  const openMobileModal = () => {
    setMobileModalOpen(true);
    setMtimer(60);
    setMobileTimerActive(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setMtimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(interval);
          setMobileTimerActive(false);
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [mobiletimerActive]);

  let mobileUpdateData = {
    country_code: "+91",
    phone_number: mobileNo,
    user_id: userId
  };
  const handleMobileUpdate = () => {
    if (mobileNo !== userProfileDetails.mobile) {
      setSendingMobileOtp(true);
      fetch(`${ApiBaseUrl}/fr-user/initiate-update-mobile-only`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken
        },
        body: JSON.stringify(mobileUpdateData)
      })
        .then((response) => {
          if (!response.ok) {
            console.log("not ok");
          }

          return response.json();
        })
        .then((res) => {
          //console.log('Response from server:', res);

          if (res.success) {
            setSendingMobileOtp(false);
            setMobileOtpSent(true);

            setAlertMsgForMobile(res.data.msg);
            openMobileModal();

            setTimeout(() => {
              setAlertMsgForMobile("");
              setMobileOtpSent(false);

              setSessionForMobile(res.data.sessionForOTP);
            }, 3000);
          }

          if (!res.success) {
            setSendingMobileOtp(false);

            setAlertMsgForMobile(res.data.msg);
            setErrorMobileOtpSent(true);
            setTimeout(() => {
              setAlertMsgForMobile("");
              setErrorMobileOtpSent(false);
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("error:", error);
        });
    } else {
      setMobileIsEditable(false);
    }
  };

  const mobileNoVerifyReqBody = {
    user_id: userId,
    sessionForOTP: sessionForMobileNo,
    answer: otpMobile
  };
  const handleVerifyMobileUpdateOtp = () => {
    setaMobileVerifyLoading(true);
    fetch(`${ApiBaseUrl}/fr-user/verify-update-mobile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify(mobileNoVerifyReqBody)
    })
      .then((response) => {
        // if (!response.ok) {
        //   console.log("not ok");
        // }

        return response.json();
      })
      .then((res) => {
        //console.log('Response from server:', res);

        if (res.success) {
          localStorage.setItem("user_mobile_no", res.data.data.mobile);
          localStorage.setItem("user_country_code", "+91");
          setaMobileVerifyLoading(false);
          setMobileOtpVerified(true);
          setAlertMsgMobVerify(res.data.msg);
          closeMobileModal();
          setTimeout(() => {
            setMobileOtpVerified(false);
            setAlertMsgMobVerify("");
          }, 3000);
        }

        if (!res.success) {
          setaMobileVerifyLoading(false);
          setAlertMsgMobVerify(res.data.msg);
          setMobileOtpVerifyErr(true);
          setTimeout(() => {
            setAlertMsgMobVerify("");
            setMobileOtpVerifyErr(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("error:", error);
      });
  };

  const handleMobileResend = () => {};

  //console.log(customLabels)
  return (
    <>
      {isUpdated && <SuccessAlert message="Profile is Updated Successfully" />}

      {isOtpSentForEmail && <SuccessAlert message="OTP Sent Successfully" />}
      {isOtpverifyError && (
        <ErrorAlert message="Invalid OTP.Please Try Again" />
      )}
      {isEmailUpdated && <SuccessAlert message="Email Updated Successfully" />}
      {isAmlupdated && (
        <SuccessAlert message="AML Declaration is Updated Successfully" />
      )}

      {isLoadingProfile && (
        <div className="">
          <img src={loader} alt="" srcset="" className="loaderBox" />
        </div>
      )}

      {isLoadingAml && (
        <div className="">
          <img src={loader} alt="" srcset="" className="loaderBox" />
        </div>
      )}
      {/* mobile no update loader */}
      {isSendiingMobileOtp && (
        <div className="">
          <img src={loader} alt="" srcset="" className="loaderBox" />
        </div>
      )}

      {isProfileLoading && (
        <div className="">
          <img src={loader} alt="" srcset="" className="loaderBox" />
        </div>
      )}

      {isMobileVerifyLoading && (
        <div className="">
          <img src={loader} alt="" srcset="" className="loaderBox" />
        </div>
      )}

      {showNameUpdateAlert && (
        <WarningAlert message="Please Update Your Name" />
      )}
      {showAlreadyEmailExist && <ErrorAlert message="Email Already In Use" />}

      {/* all alert of mobile no update  */}

      {isMobileOtpsent && <SuccessAlert message={alertMsgForMobileNo} />}
      {isErrorMobileOtpsent && <ErrorAlert message={alertMsgForMobileNo} />}

      {/* mobile no otp verification alert */}
      {isMobileOtpVerifyErr && <ErrorAlert message={alertMsgMobVerify} />}

      {isMobileOtpVerified && <SuccessAlert message={alertMsgMobVerify} />}

      {/* email-otp-modal */}
      {isModalOpen && (
        <ProfileModal closeModal={closeModal}>
          <div className="flex flex-col gap-7 font-nunito">
            <p className="text-secondary text-xl font-bold text-center">
              OTP Verification
            </p>
            <p className="text-lg font-normal text-center">
              Enter 4 digit OTP sent to{" "}
              <span className="text-secondary">
                {updatedEmail && updatedEmail}
              </span>{" "}
              .{" "}
              <span className="cursor-pointers underline" onClick={closeModal}>
                Edit
              </span>{" "}
            </p>

            <div className="flex flex-col gap-9 ">
              <div className="flex flex-col gap-9 ">
                <OtpTab
                  length={4}
                  maxChar={1}
                  setOtp={setOtp}
                  otp={otp}
                  setAllInputsFilled={setAllInputsFilled}
                />
                <button
                  disabled={!allInputsFilled || isVerifying}
                  className={`bg-secondary text-white verifyBtn-profile ${
                    allInputsFilled
                      ? "bg-secondary text-white"
                      : "disable-btn-bg disabled"
                  } `}
                  onClick={handleOnlyemailVerify}
                >
                  Verify
                </button>
              </div>

              <div>
                {timerActive ? (
                  <p className="text-sm text-center">
                    00:{timer < 10 ? `0${timer}` : timer}
                  </p>
                ) : (
                  ""
                )}
                {/* <p className='text-sm text-center'>00:60</p>  */}

                {!timerActive ? (
                  <div className="flex gap-5  items-center justify-center ">
                    <p className="text-xs sm:text-lg font-normal text-gray">
                      Didn’t receive any code?
                      <span
                        className="text-xs sm:text-sm font-normal text-secondary"
                        onClick={handleResend}
                      >
                        &nbsp; RESEND
                      </span>
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </ProfileModal>
      )}

      {/* mobile-otp-modal */}
      {isMobileModalOpen && (
        <ProfileModal closeModal={closeMobileModal}>
          <div className="flex flex-col gap-7 font-nunito">
            <p className="text-secondary text-xl font-bold text-center">
              OTP Verification
            </p>
            <p className="text-lg font-normal text-center">
              Enter 4 digit OTP sent to{" "}
              <span className="text-secondary">{mobileNo && mobileNo}</span> .{" "}
              <span
                className="cursor-pointers underline"
                onClick={closeMobileModal}
              >
                Edit
              </span>{" "}
            </p>

            <div className="flex flex-col gap-9 ">
              <div className="flex flex-col gap-9 ">
                <OtpTab
                  length={4}
                  maxChar={1}
                  setOtp={setOtpMobile}
                  otp={otpMobile}
                  setAllInputsFilled={setAllMobileInputsFilled}
                />
                <button
                  disabled={!allMobileInputsFilled && isMobileVerifyLoading}
                  className={`bg-secondary text-white verifyBtn-profile ${
                    allMobileInputsFilled && !isMobileVerifyLoading
                      ? "bg-secondary text-white"
                      : "disable-btn-bg disabled"
                  } `}
                  onClick={handleVerifyMobileUpdateOtp}
                >
                  Verify
                </button>
              </div>

              <div>
                {mobiletimerActive ? (
                  <p className="text-sm text-center">
                    00:{mtimer < 10 ? `0${mtimer}` : mtimer}
                  </p>
                ) : (
                  ""
                )}
                {/* <p className='text-sm text-center'>00:60</p>  */}

                {!mobiletimerActive ? (
                  <div className="flex gap-5  items-center justify-center ">
                    <p className="text-xs sm:text-lg font-normal text-gray">
                      Didn’t receive any code?
                      <span
                        className="text-xs sm:text-sm font-normal text-secondary"
                        onClick={handleMobileUpdate}
                      >
                        &nbsp; RESEND
                      </span>
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </ProfileModal>
      )}

      <div className="">
        <div className="text-center w-full   px-1 sm:px-2.5  pb-4">
          <p className="text-2xl sm:text-base font-medium text-center sm:text-left">
            Edit Profile
          </p>
        </div>

        <div className="profileSection">
          <div className="innerPart  flex flex-col gap-4">
            <div className="topPart tab-shadow">
              <div className="formSection flex flex-col lg:flex-row">
                <div className="flex flex-col gap-4  basis-1/5">
                  <div className="photoContainer">
                    <div class="avatar-container">
                      {selectedProfilePic.base64 ? (
                        <img
                          className="profile-img"
                          src={selectedProfilePic.base64}
                          alt="Avatar"
                        />
                      ) : proflePic ? (
                        <img
                          className="profile-img"
                          src={`${PropertImageBaseUrl}/${proflePic}`}
                          alt="Avatar"
                        />
                      ) : (
                        <img
                          className="avatar-img"
                          src={userIcon}
                          alt="Avatar"
                        />
                      )}

                      <input
                        type="file"
                        onChange={handleInputChange}
                        name="profile_pic"
                        id="file-upload"
                        accept="image/jpeg,image/png"
                      />
                    </div>

                    <label for="file-upload" class="upload-icon">
                      <MdOutlineCameraAlt />
                    </label>
                  </div>

                  {/* display-user-name */}
                  {fileError && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {fileError}
                    </p>
                  )}
                  <p
                    className="text-xl font-semibold w-full"
                    style={{ wordBreak: "break-all", maxWidth: "100%" }}
                  >
                    {userProfileDetails && userProfileDetails.first_name}
                  </p>
                </div>

                {/********* * form ***************/}

                <div className="flex flex-col gap-4   basis-4/5">
                  <div className="flex flex-col lg:flex-row">
                    <div className="flex w-full flex-col gap-2.5 sm:px-5">
                      <p className="text-sm font-normal text-left">
                        Name <span className="text-red">*</span>{" "}
                      </p>
                      <CustomeProfileInput
                        name="first_name"
                        disabled={kycStatus === "APPROVED"}
                        placeholder="Enter Your Name"
                        onChange={handleInputChange}
                        maxLength={70}
                        value={
                          userProfileDetails && userProfileDetails.first_name
                        }
                      />
                    </div>

                    <div
                      className={`flex w-full flex-col gap-2.5 py-3 sm:py-0 sm:px-5`}
                    >
                      <p className="text-sm font-normal text-left">
                        Mobile <span className="text-red">*</span>
                      </p>
                      <div className="inputWithIcon">
                        <ReactFlagsSelect
                          selectButtonClassName="menu-flags-profile"
                          className="menu-flags"
                          countries={["IN"]}
                          selected={selected}
                          onSelect={(code) => setSelected(code)}
                          searchPlaceholder="Search countries"
                          showSelectedLabel={false}
                          customLabels={customLabels}
                          showSecondarySelectedLabel={false}
                          disabled={kycStatus === "APPROVED"}
                        />

                        <CustomeProfileInput
                          name="mobile"
                          value={mobileNo && mobileNo}
                          onChange={(e) => {
                            setMobileNo(e.target.value);
                          }}
                          maxLength={10}
                          disabled={!isMobileEditable}
                        />

                        {kycStatus !== "APPROVED" &&
                          (isMobileEditable ? (
                            <>
                              <p
                                className="text-xs editIcon"
                                onClick={handleMobileUpdate}
                              >
                                Update
                              </p>
                            </>
                          ) : (
                            <AiOutlineEdit
                              className="editIcon"
                              onClick={() => setMobileIsEditable(true)}
                            />
                          ))}

                        {/* {isMobileEditable ? (
                            <>
                        <p className="text-xs editIcon"  onClick={handleMobileUpdate}>Update</p>
                            </>
                           
                          ) : (

                            <AiOutlineEdit className="editIcon" onClick={() => setMobileIsEditable(true)} />
                          )} */}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col lg:flex-row">
                    <div className={`flex w-full flex-col gap-2.5 sm:px-5`}>
                      <p className="text-sm font-normal text-left">
                        Email <span className="text-red">*</span>
                      </p>
                      <div className="inputWithIcon">
                        <CustomeProfileInput
                          value={updatedEmail && updatedEmail}
                          placeholder="Enter Your Email"
                          onChange={(e) => {
                            setUpdatedEmail(e.target.value);
                          }}
                          name="email"
                          disabled={!isEmailEditable}
                        />
                        {/* Changed lines below */}
                        {isEmailEditable ? (
                          <p
                            className="text-xs editIcon"
                            onClick={handleEmailUpdate}
                          >
                            Update
                          </p>
                        ) : (
                          <AiOutlineEdit
                            className="editIcon"
                            onClick={() => setEmailEditable(true)}
                          />
                        )}
                      </div>
                    </div>

                    <div className="flex w-full flex-col gap-2.5 py-3 sm:py-0 sm:px-5">
                      <p className="text-sm font-normal text-left">
                        Gender <span className="text-red">*</span>
                      </p>

                      {/* disabled={kycStatus==="APPROVED"} */}

                      <select
                        name="gender"
                        disabled={kycStatus === "APPROVED"}
                        onChange={handleInputChange}
                        value={userProfileDetails && userProfileDetails.gender}
                        className={`${
                          kycStatus === "APPROVED" ? "disabledbg" : ""
                        }  border grow border-gray text-sm text-gray rounded-md  sm:rounded-lg profile-input`}
                      >
                        <option selected disabled>
                          Select
                        </option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Other">Others</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row">
                    <div className={`flex sm:w-1/2 flex-col gap-2.5 sm:px-5`}>
                      <p className="text-sm font-normal text-left">
                        Date of Birth <span className="text-red">*</span>
                      </p>
                      <input
                        type="date"
                        className={`${
                          kycStatus === "APPROVED" ? "disabledbg" : ""
                        } border grow border-gray  text-sm text-gray rounded-md  sm:rounded-lg   profile-input`}
                        placeholder=""
                        disabled={kycStatus === "APPROVED"}
                        value={
                          userProfileDetails && userProfileDetails.dob
                            ? changeDateFormat(userProfileDetails.dob)
                            : ""
                        }
                        onChange={handleInputChange}
                        name="dob"
                        //  max={new Date().toISOString().split("T")[0]}
                        max={
                          new Date(
                            new Date().getFullYear() - 18,
                            new Date().getMonth(),
                            new Date().getDate()
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                      />

                      {!isDate18 && (
                        <p className="text-red text-xs -mt-1">
                          Age must be at least 18 years old.
                        </p>
                      )}
                    </div>

                    <div className="flex sm:w-1/2 flex-col gap-2.5 py-3 sm:py-0 sm:px-5">
                      <p className="text-sm font-normal text-left">
                        State <span className="text-red">*</span>
                      </p>

                      <select
                        name="state_short_code"
                        //disabled={kycStatus==="APPROVED"}
                        onChange={handleInputChange}
                        value={
                          userProfileDetails &&
                          userProfileDetails.state_short_code
                        }
                        className="border grow border-gray text-sm text-gray rounded-md  sm:rounded-lg profile-input"
                      >
                        <option selected disabled>
                          Select
                        </option>
                        {indianStatesCode &&
                          indianStatesCode.map((el) => (
                            <option value={el.code}>{el.label}</option>
                          ))}
                      </select>
                    </div>
                  </div>

                  {/* address */}
                  <div className="flex flex-col lg:flex-row">
                    <div className={`flex lg:w-1/2 flex-col gap-2.5 sm:px-5`}>
                      <p className="text-sm font-normal text-left">
                        Current Address <span className="text-red">*</span>
                      </p>
                      <textarea
                        //disabled={kycStatus==="APPROVED"}
                        class="addresssTextaRea"
                        name="address"
                        placeholder="Enter your current address"
                        onChange={handleInputChange}
                        value={userProfileDetails && userProfileDetails.address}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="saveBtnSection flex justify-between">
                <div className="lg:flex flex-col gap-4 hidden basis-1/5"></div>
                <div className="flex flex-col gap-4 sm:basis-4/5 ">
                  <button
                    className={`saveBtn ${
                      isFieldsFilled && isuserDetailsChanged
                        ? "activeSaveBtn"
                        : "disableSaveBtn"
                    } ml-4`}
                    disabled={
                      !isFieldsFilled || !isuserDetailsChanged || isUpdated
                    }
                    onClick={handleSaveChange}
                  >
                    {isLoadingProfile ? "Saving..." : "Save Changes"}
                  </button>

                  <p className="text-sm text-gray text-left">
                    <span className="font-semibold">Note:</span> You can edit
                    all details before completing KYC. After KYC, only email,
                    current address, state and profile picture can be changed.
                  </p>
                </div>
              </div>

              <div className="saveBtnSection-mobile">
                <button
                  className={`saveBtn ${
                    isuserDetailsChanged ? "activeSaveBtn" : "disableSaveBtn"
                  }`}
                  disabled={!isuserDetailsChanged || isUpdated}
                  onClick={handleSaveChange}
                >
                  {isLoadingProfile ? "Saving..." : "Save Changes"}
                </button>
              </div>
            </div>

            <div className="amlPart tab-shadow ">
              <ProfileAccordianTab
                title="AML Declaration"
                showInfoIcon={true}
                tooltiptext="AML Declaration"
                tootltipdetails="An anti-money laundering (AML) declaration is required as per the Prevention of Money Laundering Act, 2002 (PMLA).
  These regulations have been created to prohibit any criminal activities that generate income through our platform."
              >
                <div className="formSection flex flex-col lg:flex-row">
                  <div className="lg:flex flex-col gap-4 hidden  basis-1/5"></div>
                  <div className="flex flex-col gap-4  basis-4/5">
                    <div className="flex flex-col lg:flex-row">
                      <div className="flex w-full flex-col gap-2.5  sm:px-5">
                        <p className="text-sm font-normal text-left text-gray">
                          Occupation{" "}
                        </p>

                        <select
                          name="occupation"
                          value={UserAmlDetails && UserAmlDetails.occupation}
                          className="border grow border-gray text-sm text-gray rounded-md  sm:rounded-lg profile-input"
                          onChange={handleAmlInputChange}
                        >
                          <option selected disabled className="text-gray">
                            Select
                          </option>
                          <option value="Academic-Education">
                            Academic/Education
                          </option>
                          <option value="Information-Technology">
                            Information Technology
                          </option>
                          <option value="Healthcare-and-Medical">
                            Healthcare and Medical
                          </option>
                          <option value="Business-and-Finance">
                            Business and Finance
                          </option>
                          <option value="Arts-and-Design">
                            Arts and Design
                          </option>
                          <option value="Legal-and-Law">Legal and Law</option>
                          <option value="Service-and-Hospitality">
                            Service and Hospitality
                          </option>
                          <option value="Engineering-and-Manufacturing">
                            Engineering and Manufacturing
                          </option>
                          <option value="Construction-and-Trades">
                            Construction and Trades
                          </option>
                          <option value="Media-and-Communications">
                            Media and Communications
                          </option>
                          <option value="Other">Other</option>
                        </select>
                      </div>

                      <div className="flex w-full flex-col gap-2.5 sm:px-5">
                        <p className="text-sm font-normal text-left text-gray">
                          Source of Income <span className="text-red">*</span>{" "}
                        </p>

                        <select
                          name="source_of_income"
                          value={
                            UserAmlDetails && UserAmlDetails.source_of_income
                          }
                          className="border grow border-gray text-sm text-gray rounded-md  sm:rounded-lg profile-input"
                          onChange={handleAmlInputChange}
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          <option value="Salary and Bonus">
                            Salary and Bonus
                          </option>
                          <option value="Personal Savings">
                            Personal Savings
                          </option>
                          <option value="Business Loans">Business Loans</option>
                          <option value="Debenture">Debenture</option>
                          <option value="Investors">Investors</option>
                          <option value="Share of Sales/Profits">
                            Share of Sales/Profits
                          </option>
                          <option value="Dividends">Dividends</option>
                          <option value="Retained Earnings">
                            Retained Earnings
                          </option>
                          <option value="Asset / Property Sales">
                            Asset / Property Sales
                          </option>
                          <option value="Lease Back">Lease Back</option>
                          <option value="Inheritances">Inheritances</option>
                          <option value="Gifts">Gifts</option>
                          <option value="Grants">Grants</option>
                          <option value="Pension Releases">
                            Pension Releases
                          </option>
                          <option value="Compensation from Legal Rulings">
                            Compensation from Legal Rulings
                          </option>
                          <option value="Gambling Profit">
                            Gambling Profit
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="flex flex-row items-center">
                      <div className="flex w-full flex-col gap-2.5 sm:px-5">
                        <div className="flex sm:gap-2 items-center">
                          <p className="text-sm font-normal text-left text-blue ">
                            Are you Politically exposed person(PEP)?{" "}
                          </p>
                          <div className="tooltip">
                            <IoMdInformationCircleOutline
                              style={{ fontSize: "14px", color: " #D328A7" }}
                            />
                            <span className="tooltippolitical">
                              <p className="text-blue text-sm ">
                                Are You A Politically Exposed Person (PEP)?
                              </p>
                              <p className="text-gray text-xs">
                                As per RBI, a Politically Exposed Person
                                includes individuals who have been given
                                prominent public functions by a foreign country,
                                like:
                                <ul>
                                  <li> 1.Senior Politicians</li>
                                  <li> 2. Head of state or government</li>
                                  <li>
                                    {" "}
                                    3. Senior government, judicial, or military
                                    officers
                                  </li>
                                  <li>
                                    {" "}
                                    4. Senior executives of state-owned
                                    corporations
                                  </li>
                                  <li>
                                    {" "}
                                    5. Important political party officials
                                  </li>
                                </ul>
                              </p>
                            </span>
                          </div>

                          <IoMdInformationCircleOutline
                            className="block sm:hidden"
                            style={{ fontSize: "21px", color: " #D328A7" }}
                            onClick={handleshowpoliticalPopup}
                          />
                        </div>

                        {showpoliticalPopup && (
                          <>
                            <InfoPopup>
                              <div className="p-2">
                                <p className="text-blue text-left text-sm mb-1">
                                  Are You A Politically Exposed Person (PEP)?
                                </p>
                                <p className="text-gray text-left text-xs">
                                  As per RBI, a Politically Exposed Person
                                  includes individuals who have been given
                                  prominent public functions by a foreign
                                  country, like:
                                  <ul>
                                    <li> 1.Senior Politicians</li>
                                    <li> 2. Head of state or government</li>
                                    <li>
                                      {" "}
                                      3. Senior government, judicial, or
                                      military officers
                                    </li>
                                    <li>
                                      {" "}
                                      4. Senior executives of state-owned
                                      corporations
                                    </li>
                                    <li>
                                      {" "}
                                      5. Important political party officials
                                    </li>
                                  </ul>
                                </p>
                              </div>

                              <button
                                onClick={handleclosepoliticalPopup}
                                className="bg-secondary w-full rounded-md py-2 font-bold text-sm text-white"
                              >
                                Ok
                              </button>
                            </InfoPopup>
                          </>
                        )}
                      </div>

                      <div className="flex w-full flex-row-reverse sm:flex-col gap-2.5 sm:px-5">
                        <ToggleSwitch
                          defaultChecked={isChecked}
                          isChecked={isChecked}
                          setIsChecked={setIsChecked}
                          name="is_pep"
                          onChange={handleToggleSwitchChange}
                        />
                      </div>
                    </div>

                    {/* brief-section */}

                    {isChecked && (
                      <div className="flex flex-col lg:flex-row items-center">
                        <div className="flex w-full flex-col gap-2.5 sm:px-5">
                          <textarea
                            class="amlTextaRea"
                            name="pep_info"
                            placeholder="Please brief about it"
                            onChange={handleAmlInputChange}
                            value={UserAmlDetails && UserAmlDetails.pep_info}
                          />
                        </div>
                      </div>
                    )}

                    {/* brief-section */}
                  </div>
                </div>

                <div class="saveBtnSection flex justify-between">
                  <div class="lg:flex flex-col gap-4 hidden basis-1/5"></div>
                  <div class="flex flex-col gap-4 sm:basis-4/5 ml-3">
                    <button
                      class={`saveBtn ${
                        isamlvalueChanged ? "activeSaveBtn" : "disableSaveBtn"
                      }`}
                      onClick={handleAmlUpdate}
                      disabled={!isamlvalueChanged || isAmlupdated}
                    >
                      {isLoadingAml ? "Saving..." : "Save Changes"}
                    </button>
                  </div>
                </div>

                <div className="saveBtnSection-mobile">
                  <button
                    className={`saveBtn ${
                      isamlvalueChanged ? "activeSaveBtn" : "disableSaveBtn"
                    }`}
                    disabled={!isamlvalueChanged || isAmlupdated}
                    onClick={handleAmlUpdate}
                  >
                    {isLoadingAml ? "Saving..." : "Save Changes"}
                  </button>
                </div>
              </ProfileAccordianTab>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
